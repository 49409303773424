import * as SecurityService from 'app/business-logic/services/security-service';
import { store } from 'app/redux/store';
import urlJoin from 'url-join';
import { AppSettings } from 'core/AppSettings';
import AuthParameters from './AuthParameters';

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  createHttpLink as createApolloHttpLink,
  from,
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';

import { LogManager } from 'core/logging/LogManager';

import possibleTypes from './global.possible-types.json';

const logger = LogManager.getLogger('ApolloProvider');

let globalClient: DefaultClient;

class DefaultClient extends ApolloClient<NormalizedCacheObject> {}

type Params = { companyAlias: string; facilityAlias: string };

export class ApolloProvider {
  public static global(params?: Params): DefaultClient {
    const link = createProviderLink(params);
    return (
      globalClient ||
      (globalClient = new DefaultClient({
        link,
        cache: new InMemoryCache({
          possibleTypes,
        }),
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
          },
        },
      }))
    );
  }
}

function getAuthDomain() {
  const { companyAlias, facilityAlias } = store.getState().profile.facility;
  if (!companyAlias || !facilityAlias) throw new Error('The facility is missing');
  return { companyAlias, facilityAlias };
}

export const createAuthLink = (params?: Params) => {
  return new ApolloLink((operation, forward) => {
    const { companyAlias, facilityAlias } = params ?? getAuthDomain();
    const headers = AuthParameters.createRequestHeaders(companyAlias, facilityAlias);
    operation.setContext({ headers });
    return forward(operation);
  });
};

export const createErrorLink = () => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations }) =>
        logger.warn(`[GraphQL error]: Message: ${message}, Locations`, locations)
      );
    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
      logger.error('Unauthorised request, redirecting to login page');
      window.location.href = SecurityService.getLoginUrl();
    }
    if (networkError) logger.error(`[Network error]: ${networkError}`);
  });
};

export const createHttpLink = () => {
  const uri: string = urlJoin(AppSettings.publicApiUrl, 'graphql');
  return createApolloHttpLink({ uri, credentials: 'include' });
};

export const createProviderLink = (params?: Params) => {
  const authLink = createAuthLink(params);
  const httpLink = createHttpLink();
  const errorLink = createErrorLink();
  return from([authLink, errorLink, httpLink]);
};

export default ApolloProvider;
