import { useQuery } from '@tanstack/react-query';
import { GetProcessVariableDataQuery } from 'app/__generated__/global';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { getDistinctColours } from 'app/helpers/getDistinctColours';
import { useThemeContext } from 'app/theme/useThemeContext';
import Guid from 'core/types/Guid';
import { useCallback, useMemo } from 'react';
import { getProcessVariableSeriesData } from './getProcessVariableSeriesData';
import { EventsChartSeries } from './useEventsChartOptions.types';

export const useProcessVariableSeries = ({
  traceVariableIds,
  utcStartDate,
  utcEndDate,
}: Partial<{
  traceVariableIds: Guid[];
  utcStartDate: string;
  utcEndDate: string;
}>) => {
  const hasAllParameters = !!traceVariableIds?.length && !!utcStartDate && !!utcEndDate;
  const convertResponseToSeries = useConvertResponseToSeries();
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEY.traceVariablesSeries, utcStartDate, utcEndDate, traceVariableIds?.join(', ')],
    queryFn: () => {
      if (!hasAllParameters) return;
      return getProcessVariableSeriesData({ traceVariableIds, utcStartDate, utcEndDate });
    },
    enabled: hasAllParameters,
  });
  return useMemo(() => {
    return {
      data: convertResponseToSeries(data?.data),
      ...rest,
    };
  }, [convertResponseToSeries, data, rest]);
};
function useConvertResponseToSeries() {
  const { colors } = useThemeContext();
  return useCallback(
    (data: GetProcessVariableDataQuery | undefined) => {
      if (!data) return;
      const variables = data.facility?.processVariables ?? [];
      const seriesColours = [colors.brand.brand02, ...getDistinctColours(variables.length - 1)];
      const series: EventsChartSeries[] = variables.map(({ variableData, name, process }, index) => ({
        type: 'line',
        data: variableData?.data.map(([dateTime, value]) => [dateTime, value]) ?? [],
        color: seriesColours.at(index)!,
        name,
        title: process.name,
      }));
      return series;
    },
    [colors]
  );
}
