import SideBarItem, { INavItem } from 'app/views/components/navigation-bar/NavItem';
import { useRouting } from 'core/routing/Routing';

import { DATA_TRACKING_KEY } from '../heap-analytics/enums';
import { Logo, LogoLink, SideBarLinkList, SideBarTitleContainer, StyledSideBar } from './NavigationBar.styles';
import { useDesktopNavigationVisibility } from 'app/views/components/navigation-bar/useDesktopNavigationVisibility';

interface ISideBarProps {
  topItems?: INavItem[];
  bottomItems?: INavItem[];
}

const DATA_TRACKING_KEY_BY_ITEM_KEY: Record<string, string> = {
  overview: DATA_TRACKING_KEY['main-nav-bar-overview-link'],
  monitoring: DATA_TRACKING_KEY['main-nav-bar-monitoring-link'],
  modelling: DATA_TRACKING_KEY['main-nav-bar-modelling-link'],
  alerts: DATA_TRACKING_KEY['main-nav-bar-alerts-link'],
  trajectories: DATA_TRACKING_KEY['main-nav-bar-trajectories-link'],
  blasting: DATA_TRACKING_KEY['main-nav-bar-blasting-link'],
  intelligence: DATA_TRACKING_KEY['main-nav-bar-incident-intelligence-link'],
  annotations: DATA_TRACKING_KEY['main-nav-bar-annotations-link'],
  reports: DATA_TRACKING_KEY['main-nav-bar-reports-link'],
  siteConfig: DATA_TRACKING_KEY['main-nav-bar-site-config-link'],
  userSettings: DATA_TRACKING_KEY['main-nav-bar-user-settings-link'],
  support: DATA_TRACKING_KEY['main-nav-bar-support-link'],
  logout: DATA_TRACKING_KEY['main-nav-bar-logout-link'],
} as const;

const SideBar = ({ topItems, bottomItems }: ISideBarProps) => {
  const { routes } = useRouting();
  const { isOpen, onMouseEnter, onMouseLeave } = useDesktopNavigationVisibility();
  return (
    <StyledSideBar $isOpen={isOpen} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <SideBarTitleContainer>
        <LogoLink to={routes.monitoring.realTime()} data-tracking={DATA_TRACKING_KEY['main-nav-bar-logo']}>
          <Logo title="Omnis | Envirosuite" />
        </LogoLink>
      </SideBarTitleContainer>
      <SideBarLinkList>
        <SideBarItemList items={topItems as INavItem[]} />
        <SideBarItemList items={bottomItems as INavItem[]} />
      </SideBarLinkList>
    </StyledSideBar>
  );
};

const SideBarItemList = ({ items }: { items: INavItem[] }) => {
  return (
    <div>
      {items.map(link => (
        <SideBarItem
          {...link}
          key={link.key}
          elementProps={{ ...link.elementProps, ...{ 'data-tracking': DATA_TRACKING_KEY_BY_ITEM_KEY[link.key] } }}
        />
      ))}
    </div>
  );
};

export default SideBar;
