import { CaseReducer } from '@reduxjs/toolkit';
import { fetchEmissionSources } from '../../fetchEmissionSources';
import { ModellingState } from '../../slice';

export const fetchEmissionSourcesFulfilled: CaseReducer<
  ModellingState,
  ReturnType<typeof fetchEmissionSources.fulfilled>
> = (state, { payload, meta: { requestId } }) => {
  state.data.emissionSources = { status: 'Success', requestId, data: payload };
};
