import { CaseReducer } from '@reduxjs/toolkit';
import { fetchAlertPointReports } from '../fetchAlertPointReports';
import { ModellingState } from '../slice';

export const fetchAlertPointReportsFulfilled: CaseReducer<
  ModellingState,
  ReturnType<typeof fetchAlertPointReports.fulfilled>
> = (state, { payload, meta: { requestId } }) => {
  if (state.data.alertPointReports.status !== 'Loading') return state;
  if (state.data.alertPointReports.requestId !== requestId) return state;
  return {
    ...state,
    data: {
      ...state.data,
      alertPointReports: {
        status: 'Success',
        requestId,
        data: payload,
      },
    },
  };
};
