import { gql } from '@apollo/client';

export const GET_PROCESS_VARIABLE_DATA = gql(`
  query GetProcessVariableData(
      $variableFilter: VariableFilter
      $variableDataFilter: VariableDataFilter
  ) {
    facility {
      processVariables(filter: $variableFilter) {
        id
        name
        process {
          id
          name
        }
        variableData(filter: $variableDataFilter) {
          data
        }
      }
    }
  }
`);
