import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchModelDataSet,
  FetchModelDataSetBody,
} from 'app/business-logic/services/modelling-service/fetchModelDataSet';
import { IGlobalState } from '../reducer';
import { ModelRunData } from './types';

export const fetchModelData = createAsyncThunk<ModelRunData, FetchModelDataSetBody, { state: IGlobalState }>(
  'modelling/fetchModelData',
  async body => {
    return await fetchModelDataSet(body);
  }
);
