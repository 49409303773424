import { between, down, not, only, up } from 'app/helpers/breakpoints';

const xs = 0;
const sm = 600;
const md = 900;
const lg = 1200;
const xl = 1536;

const XS = 'xs';
const SM = 'sm';
const MD = 'md';
const LG = 'lg';
const XL = 'xl';

const BREAKPOINT_VALUES = {
  [XS]: xs,
  [SM]: sm,
  [MD]: md,
  [LG]: lg,
  [XL]: xl,
};

export const BREAKPOINTS = {
  values: BREAKPOINT_VALUES,
  between: between(BREAKPOINT_VALUES),
  down: down(BREAKPOINT_VALUES),
  not: not(BREAKPOINT_VALUES),
  only: only(BREAKPOINT_VALUES),
  up: up(BREAKPOINT_VALUES),
};
