import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { deselectAlertEvent, selectAlertEvent } from 'app/redux/ui/actions';
import { useI18n } from 'core/hooks/useI18n';
import { DateTime } from 'luxon';
import { ComponentProps, Fragment, useCallback, RefObject, useMemo, MutableRefObject, Suspense } from 'react';

import { EventNoisePlayback } from '../event-noise-playback';
import { EventElementsMap } from '../events-chart/use-select-alert-event';
import {
  ActionCell,
  EventsTable,
  EventsTableBody,
  EventsTableCell,
  EventsTableHead,
  EventsTableHeading,
  EventsTableHeadingInner,
  EventsTableHeadingText,
  EventsTableRow,
  EventsTableWrapper,
  ExpandedCell,
  ExpandRowButton,
  ExpandRowButtonIcon,
  Loading,
  LoadingEventsThrobber,
  NoEvents,
  NoMoreEvents,
} from './EventsListing.styles';
import { EventClassification } from '../event-classification';
import { EventClassificationIcons } from '../event-classification-icons';
import { EventClassificationComments } from '../event-classification-comments';
import { useHasAccess } from 'app/hooks/use-has-access';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { useMediaQuery } from 'app/helpers/useMediaQuery';
import { useThemeContext } from 'app/theme/useThemeContext';
import { NoiseEvent } from 'app/business-logic/services/noise-service';
import { Throbber } from 'app/components/throbber';
import {
  flexRender,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Header } from '@tanstack/table-core';
import { Classes } from '@blueprintjs/core';
import { useNoiseMonitors } from 'app/views/monitoring/useNoiseMonitors';
import { useAlertById } from '../../hooks';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useAlertResponseCategories } from 'app/views/configuration/pages/alert/alert-responses/alert-response-form/useAlertResponseCategories';

type EventsListingProps = ComponentProps<typeof EventsTableWrapper> & {
  data: NoiseEvent[] | undefined;
  isFetching: boolean;
  eventElementsMap: RefObject<EventElementsMap>;
  scrollingElementRef: MutableRefObject<HTMLDivElement | null>;
  hasNoMoreResults?: boolean;
  selectedAlertId?: string;
};

const ACTIONS_CELL_ID = 'actions';
const FALLBACK_DATA: NoiseEvent[] = [];
const NO_DATA = '--';

export const EventsListing = ({
  data,
  isFetching,
  eventElementsMap,
  scrollingElementRef,
  hasNoMoreResults,
  selectedAlertId,
  ...rest
}: EventsListingProps) => {
  const { l10n } = useI18n('app/views/alerts', 'eventsListing');
  const columns = useEventHistoryTableColumns();
  const table = useReactTable<NoiseEvent>({
    data: data ?? FALLBACK_DATA,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const { data: alert } = useAlertById(selectedAlertId);
  const { data: alertResponseCategories } = useAlertResponseCategories();
  const category = alertResponseCategories?.find(c => c.id === alert?.alertTypeId);
  const { selectedAlertEvent } = useAppSelector(state => state.ui);
  const handleExpansion = useHandleRowExpansion();
  const headerGroups = useMemo(() => table.getHeaderGroups(), [table]);
  const noMoreResults = hasNoMoreResults && !isFetching;
  const noResults = !data?.length && !isFetching;
  const theme = useThemeContext();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  if (noResults && category?.slug === 'noise') return <NoEvents>{l10n('results.noEvents')}</NoEvents>;
  if (noResults) return null;

  return (
    <EventsTableWrapper
      {...rest}
      ref={ref => {
        if (!scrollingElementRef) return;
        scrollingElementRef.current = ref;
      }}
    >
      <EventsTable $isFetching={isFetching}>
        <EventsTableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <EventsTableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                /**
                 * Disabled sorting-related functionality for the time being as it would likely result
                 * in more work, e.g. persisting state locally and returning sorted data from the server
                 */
                // const sortDirection = header.column.getIsSorted();
                // const isDescending = header.column.getIsSorted() === 'desc';
                const isActionsCell = header.column.id === ACTIONS_CELL_ID;
                return (
                  <EventsTableHeading key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                    {header.isPlaceholder || isActionsCell ? null : (
                      <EventsTableHeadingInner
                        as="span"
                        // onClick={header.column.getToggleSortingHandler()}
                        // aria-label={l10n('action.sort')}
                      >
                        <EventsTableHeadingText>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </EventsTableHeadingText>
                        {/* {sortDirection && <SortIcon data-testid="sorting-icon" $isDescending={isDescending} />} */}
                      </EventsTableHeadingInner>
                    )}
                  </EventsTableHeading>
                );
              })}
            </EventsTableRow>
          ))}
        </EventsTableHead>
        <EventsTableBody>
          {table.getRowModel().rows.map(row => {
            const { id } = row.original;
            const isExpanded = selectedAlertEvent === row.original.id;
            const cells = row.getVisibleCells();
            return (
              <Fragment key={row.original.id}>
                <EventsTableRow
                  $isSelectable={true}
                  $isExpanded={isExpanded}
                  ref={(ref: HTMLTableRowElement) => eventElementsMap.current?.set(id, ref)}
                  data-testid="events-listing-row"
                >
                  {cells.map(cell => {
                    const isActionsCell = cell.column.id === ACTIONS_CELL_ID;
                    if (isActionsCell && isDesktop) {
                      return (
                        <ActionCell
                          style={{
                            maxWidth: cell.column.getSize(),
                            width: cell.column.getSize(),
                          }}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </ActionCell>
                      );
                    }
                    return (
                      <EventsTableCell
                        onClick={handleExpansion(id)}
                        style={{
                          maxWidth: cell.column.getSize(),
                          width: cell.column.getSize(),
                        }}
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </EventsTableCell>
                    );
                  })}
                </EventsTableRow>
                {isExpanded && (
                  <EventsTableRow aria-describedby={`expand-row-button-${id}`}>
                    <ExpandedCell colSpan={cells.length}>
                      <Suspense
                        fallback={
                          <Loading>
                            <Throbber>{l10n('activity.loadingEvent')}</Throbber>
                          </Loading>
                        }
                      >
                        <EventNoisePlayback noiseEventId={id} startTime={row.original.startTime} />
                        <EventClassification event={row.original} handleExpansion={handleExpansion(id)} />
                      </Suspense>
                    </ExpandedCell>
                  </EventsTableRow>
                )}
              </Fragment>
            );
          })}
          {isFetching && <LoadingRows headers={headerGroups[0]?.headers} />}
        </EventsTableBody>
      </EventsTable>

      {noMoreResults && <NoMoreEvents>{l10n('results.noMoreEvents')}</NoMoreEvents>}

      {isFetching && <LoadingEventsThrobber>{l10n('results.loadingEvents')}</LoadingEventsThrobber>}
    </EventsTableWrapper>
  );
};

function LoadingRows({ headers }: { headers: Header<NoiseEvent, unknown>[] | undefined }) {
  if (!headers) return null;
  return (
    <>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <EventsTableRow data-testid="events-loading-row-loading" key={index}>
            {headers.map(header => (
              <EventsTableCell
                key={header.id}
                colSpan={header.colSpan}
                style={{ width: header.getSize() }}
                className={Classes.SKELETON}
              >
                &nbsp;
              </EventsTableCell>
            ))}
          </EventsTableRow>
        ))}
    </>
  );
}

const { accessor, display } = createColumnHelper<NoiseEvent>();

function useEventHistoryTableColumns() {
  const { l10n } = useI18n('app/views/alerts', 'eventsListing');
  const localTime = useLocalTimeCallback();
  const isRowExpanded = useIsRowExpanded();
  const handleExpansion = useHandleRowExpansion();
  const noiseMonitors = useNoiseMonitors();
  const hasAccess = useHasAccess();
  const isMobile = useIsMobile();

  return useMemo(() => {
    const action = {
      cell: display({
        id: ACTIONS_CELL_ID,
        cell: props => {
          const { id } = props.row.original;
          const isExpanded = isRowExpanded(id);
          return (
            <ExpandRowButton
              onClick={handleExpansion(id)}
              title={l10n('action.viewEvent', { id })}
              id={`expand-row-button-${id}`}
              aria-pressed={isExpanded}
            >
              <ExpandRowButtonIcon $isExpanded={isExpanded} />
            </ExpandRowButton>
          );
        },
      }),
      accessControl: [],
    };
    const event = {
      cell: accessor('id', {
        header: l10n('heading.event'),
        cell: ({ getValue }) => getValue(),
      }),
      accessControl: [],
    };
    const monitor = {
      cell: accessor('locationId', {
        header: l10n('heading.monitor'),
        cell: ({ getValue }) => {
          const locationId = getValue();
          return noiseMonitors.find(monitor => monitor.omnisLocationId === locationId)?.name ?? NO_DATA;
        },
        minSize: 100,
        maxSize: 150,
      }),
      accessControl: [],
    };
    const time = {
      cell: accessor('startTime', {
        header: l10n('heading.time'),
        cell: ({ getValue }) => {
          const facilityDate = localTime(getValue());
          const date = facilityDate.toLocaleString(DateTime.DATE_MED);
          const time = facilityDate.toLocaleString(DateTime.TIME_24_SIMPLE);
          return (
            <span data-testid="events-listing-event-time" data-ms={localTime(getValue()).toMillis()}>
              {date} {time}
            </span>
          );
        },
        size: 180,
      }),
      accessControl: [],
    };
    const duration = {
      cell: accessor('endTime', {
        header: l10n('heading.duration'),
        cell: ({
          row: {
            original: { startTime, endTime },
          },
        }) => {
          const totalMs = DateTime.fromISO(endTime).toMillis() - DateTime.fromISO(startTime).toMillis();
          const totalSeconds = Math.floor(totalMs / 1000);
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
      }),
      accessControl: [],
    };
    const sel = {
      cell: accessor('sel', {
        header: 'SEL',
        cell: formatEventData,
      }),
      accessControl: [],
    };
    const laeq = {
      cell: accessor('laeq', {
        header: 'LAeq',
        cell: formatEventData,
      }),
      accessControl: [],
    };
    const classifications = {
      cell: accessor('classifications', {
        header: l10n('heading.classification'),
        cell: ({ getValue }) => <EventClassificationIcons classifications={getValue()} />,
      }),
      accessControl: [KnownAccessControls.app.noise.classification.data.read._],
    };
    const comments = {
      cell: accessor('comments', {
        header: l10n('heading.comments'),
        cell: ({ getValue }) => {
          const comments = getValue();
          return comments && <EventClassificationComments comments={comments} />;
        },
      }),
      accessControl: [KnownAccessControls.app.noise.classification.data.read._],
    };
    const wind = {
      cell: accessor('windspeed', {
        header: l10n('heading.windSpeed'),
        cell: formatEventData,
      }),
      accessControl: [],
    };
    const mobileCells = [time, monitor];
    const desktopCells = [action, event, monitor, time, duration, sel, laeq, classifications, comments, wind];
    return (isMobile ? mobileCells : desktopCells).flatMap(({ cell, accessControl }) => {
      if (hasAccess(accessControl)) return [cell];
      return [];
    });
  }, [handleExpansion, hasAccess, isMobile, isRowExpanded, l10n, localTime, noiseMonitors]);
}

function useHandleRowExpansion() {
  const dispatch = useAppDispatch();
  const { selectedAlertEvent } = useAppSelector(state => state.ui);
  return useCallback(
    (id: number) => () => {
      if (selectedAlertEvent === id) return dispatch(deselectAlertEvent());
      return dispatch(selectAlertEvent(id));
    },
    [dispatch, selectedAlertEvent]
  );
}

function useIsRowExpanded() {
  const { selectedAlertEvent } = useAppSelector(state => state.ui);
  return useCallback(
    (eventId: number) => {
      return selectedAlertEvent === eventId;
    },
    [selectedAlertEvent]
  );
}

function formatEventData<P extends { getValue: () => number | null | undefined }>({ getValue }: P) {
  const value = getValue();
  if (typeof value !== 'number') return NO_DATA;
  // Wind seems to return as -1 if there is no data
  if (value === -1) return NO_DATA;
  return value.toFixed(1);
}
