import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAlertPointDataSet,
  FetchAlertPointDataBody,
} from 'app/business-logic/services/modelling-service/fetchAlertPointDataSet';
import { IAlertPointsByTimeStep } from 'app/business-logic/services/modelling-service/types';
import { IGlobalState } from '../reducer';

export const fetchAlertPointData = createAsyncThunk<
  IAlertPointsByTimeStep,
  FetchAlertPointDataBody,
  { state: IGlobalState }
>('modelling/fetchAlertPointData', async body => {
  return await fetchAlertPointDataSet(body);
});
