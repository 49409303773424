import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { useLogout } from 'app/business-logic/services/security-service';
import { Icon } from 'app/components/icon';
import { useHasAccess } from 'app/hooks/use-has-access';
import { useIsTrajectoryExport } from 'app/hooks/useIsTrajectoryExport';
import { useAppSelector } from 'app/redux/store';
import SideBar from 'app/views/components/navigation-bar/SideBar';
import { INavItem } from 'app/views/components/navigation-bar/NavItem';
import { NavWrapper } from 'app/views/Layout.styles';
import { AppSettings } from 'core/AppSettings';
import { useI18n } from 'core/hooks/useI18n';
import { Routing, useRouting } from 'core/routing/Routing';
import { useMemo } from 'react';
import urlJoin from 'url-join';

const FROM = '?from=omnis';

export const NavigationBar = () => {
  const topItems = useTopItems();
  const bottomItems = useBottomItems();
  const isTrajectoryExport = useIsTrajectoryExport();
  if (isTrajectoryExport) return null;

  return (
    <NavWrapper>
      <SideBar topItems={topItems} bottomItems={bottomItems} />
    </NavWrapper>
  );
};

export function useTopItems(): INavItem[] {
  const { l10n } = useI18n('app/views/components', 'shell.menu');
  const {
    facility: { companyAlias, facilityAlias },
  } = useAppSelector(state => state.profile);
  const { preferredMonitoringView } = useAppSelector(state => state.monitoring);
  const hasAccess = useHasAccess();
  const {
    routes: { monitoring, grid, modelling, alerts, trajectories, incidentIntelligence, blasting, annotations, reports },
  } = useRouting();

  return useMemo(() => {
    if (!companyAlias || !facilityAlias) return [];
    return [
      {
        key: 'overview',
        title: l10n('overview'),
        icon: Icon.Dashboard,
        link: urlJoin(AppSettings.es2Url, 'dashboard', companyAlias, facilityAlias, FROM),
        accessControls: [KnownAccessControls.app.dashboard._],
        isExternal: true,
      },
      {
        key: 'monitoring',
        title: l10n('monitoring'),
        icon: Icon.Monitoring,
        link: preferredMonitoringView === 'MAP' ? monitoring.realTime() : grid.realTime(),
        accessControls: [KnownAccessControls.app.monitoring._],
        path: preferredMonitoringView === 'MAP' ? Routing.routes.monitoring.root : Routing.routes.grid.root,
      },
      {
        key: 'modelling',
        title: l10n('modelling'),
        icon: Icon.Modelling,
        link: modelling.root,
        accessControls: [KnownAccessControls.app.modelling._],
        path: Routing.routes.modelling.root,
      },
      {
        key: 'alerts',
        title: l10n('alerts'),
        icon: Icon.Alerts,
        link: alerts.root,
        accessControls: [KnownAccessControls.app.alerts._],
        path: Routing.routes.alerts.root,
      },
      {
        key: 'trajectories',
        title: l10n('trajectories'),
        icon: Icon.Trajectories,
        link: trajectories.root,
        accessControls: [KnownAccessControls.app.modelling.trajectory._],
        path: Routing.routes.trajectories.root,
      },
      {
        key: 'blasting',
        title: l10n('blasting'),
        icon: Icon.Blasting,
        link: blasting.root,
        accessControls: [KnownAccessControls.app.blasting._],
        path: Routing.routes.blasting.root,
      },
      {
        key: 'intelligence',
        title: l10n('incidentIntelligence'),
        icon: Icon.IncidentIntelligence,
        link: incidentIntelligence.root,
        accessControls: [KnownAccessControls.app.incidentIntelligence._],
        path: Routing.routes.incidentIntelligence.root,
      },
      {
        key: 'annotations',
        title: l10n('annotations'),
        icon: Icon.Annotations,
        link: annotations.root,
        accessControls: [KnownAccessControls.app.config.annotations.monitorPollutants._],
        path: Routing.routes.annotations.root,
      },
      {
        key: 'reports',
        title: l10n('reports'),
        icon: Icon.Reports,
        link: reports.root,
        accessControls: [KnownAccessControls.app.reports._],
        path: Routing.routes.reports.root,
      },
    ].filter(item => hasAccess(item.accessControls ?? []));
  }, [
    companyAlias,
    facilityAlias,
    l10n,
    preferredMonitoringView,
    monitoring,
    grid,
    modelling.root,
    alerts.root,
    trajectories.root,
    blasting.root,
    incidentIntelligence.root,
    annotations.root,
    reports.root,
    hasAccess,
  ]);
}

export function useBottomItems(): INavItem[] {
  const { l10n } = useI18n('app/views/components', 'shell.menu');
  const hasAccess = useHasAccess();
  const {
    routes: { configuration, profile },
  } = useRouting();
  const handleLogout = useLogout();
  const nonGuestItems: INavItem[] = useMemo(
    () =>
      AppSettings.useGuestApiKey
        ? []
        : [
            {
              key: 'support',
              title: l10n('support'),
              icon: Icon.Support,
              link: 'https://support.envirosuite.com',
              accessControls: [],
              isExternal: true,
            },
            {
              key: 'logout',
              title: l10n('logout'),
              icon: Icon.Logout,
              accessControls: [],
              elementProps: {
                onClick: handleLogout,
                as: 'button',
              },
            },
          ],
    [handleLogout, l10n]
  );
  return useMemo(
    () =>
      [
        {
          key: 'siteConfig',
          title: l10n('siteConfig'),
          icon: Icon.SiteConfig,
          link: configuration.root,
          accessControls: [KnownAccessControls.app.config._],
          path: Routing.routes.configuration.root,
        },
        {
          key: 'userSettings',
          title: l10n('userSettings'),
          icon: Icon.UserSettings,
          link: profile.root,
          path: Routing.routes.profile.root,
        },
        ...nonGuestItems,
      ].filter(item => hasAccess(item.accessControls ?? [])),
    [l10n, configuration.root, profile.root, nonGuestItems, hasAccess]
  );
}
