import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchKmlOverlays, createKmlGeoJson } from 'app/business-logic/services/map-service';

export const EMISSION_SOURCES_FILENAME = 'sources.kml';

export const fetchEmissionSources = createAsyncThunk('modelling/fetchEmissionSources', async () => {
  const response = await fetchKmlOverlays({
    includeFiles: [EMISSION_SOURCES_FILENAME],
  });
  return createKmlGeoJson(response, [EMISSION_SOURCES_FILENAME]);
});
