import { CaseReducer } from '@reduxjs/toolkit';
import { ModellingState } from '../slice';
import { fetchModelData } from '../fetchModelData';
import { ModelRunLoading } from '../types';

export const fetchModelDataPending: CaseReducer<ModellingState, ReturnType<typeof fetchModelData.pending>> = (
  state,
  { meta }
) => {
  const {
    arg: { modelIds, utcFromDate, utcToDate },
    requestId,
  } = meta;
  const newModelIds = modelIds.filter(id => !state.data.models.map(({ modelId }) => modelId).includes(id));
  return {
    ...state,
    data: {
      ...state.data,
      models: state.data.models
        .map(model => {
          if (!modelIds.includes(model.modelId)) return model;
          return {
            ...model,
            request: { status: 'Loading', requestId },
          } as ModelRunLoading;
        })
        .concat(
          newModelIds.map(
            modelId =>
              ({ modelId, utcFromDate, utcToDate, request: { status: 'Loading', requestId } } as ModelRunLoading)
          )
        ),
    },
  };
};
