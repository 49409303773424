import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { DateTime } from 'luxon';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useEventNoisePlaybackContext } from '../wave-surfer-playback/EventNoisePlaybackContext';
import { StyledSlider } from './EventNoisePlaybackSlider.styles';
export const EventNoisePlaybackSlider = () => {
  const { durationMilliseconds } = useEventNoisePlaybackContext();
  const localTime = useLocalTimeCallback();
  const { millis, onAfterChange, onBeforeChange, onChange } = usePlaybackSliderState();
  const tipFormatter = useCallback(
    (value: number) => localTime(value).toLocaleString(DateTime.TIME_WITH_SECONDS),
    [localTime]
  );

  if (typeof durationMilliseconds !== 'number') return null;

  return (
    <StyledSlider
      tipFormatter={tipFormatter}
      min={0}
      max={durationMilliseconds}
      step={1}
      value={millis}
      onChange={onChange}
      onAfterChange={onAfterChange}
      onBeforeChange={onBeforeChange}
    />
  );
};
function usePlaybackSliderState() {
  const { pause, play, seekTo, isPlaying, millisecondsTimestamp, durationMilliseconds } =
    useEventNoisePlaybackContext();
  const shouldPlayOnResume = useRef(false); // Needs to be set through a ref because useState setter is async and doesn't work with callback conditions
  const [millis, setMillis] = useState(millisecondsTimestamp);
  const onChange = useCallback((value: number) => {
    setMillis(value);
  }, []);
  const onAfterChange = useCallback(
    (value: number) => {
      if (typeof durationMilliseconds !== 'number') return;
      if (shouldPlayOnResume.current) play();
      seekTo(value / durationMilliseconds);
    },
    [durationMilliseconds, play, seekTo, shouldPlayOnResume]
  );
  const onBeforeChange = useCallback(() => {
    if (isPlaying) shouldPlayOnResume.current = true;
    else shouldPlayOnResume.current = false;
    pause();
  }, [isPlaying, pause]);

  // Maintaining an internal state for performance. Calling "seekTo" repeatedly when scrubbing is slow.
  useLayoutEffect(() => setMillis(millisecondsTimestamp), [millisecondsTimestamp]);

  return { millis, onChange, onAfterChange, onBeforeChange };
}
