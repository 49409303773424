import { Slider, SliderProps } from '../slider';
import { TipFormatter } from './SliderWithTooltip.types';
import { useHandleRender } from './useHandleRender';

export type SliderWithTooltipProps = SliderProps & {
  tipFormatter?: TipFormatter;
};

export const SliderWithTooltip = (props: SliderWithTooltipProps) => {
  const { tipFormatter, ...rest } = props;
  const { value } = props;
  const handleRender = useHandleRender(value, tipFormatter);
  return <Slider handleRender={handleRender} {...rest} />;
};
