import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';
import Guid from 'core/types/Guid';
import { IAlertPointsByTimeStep } from './types';

export type FetchAlertPointDataBody = {
  modelId: Guid;
  utcFromTime: string;
  utcToTime: string;
};

export async function fetchAlertPointDataSet({ modelId, utcFromTime, utcToTime }: FetchAlertPointDataBody) {
  const { data, isSuccess }: HttpResponse<IAlertPointsByTimeStep> = await httpClient.useInternalApi
    .asAuthorised()
    .post('gis-models/discrete-receptor-data', {
      modelId,
      utcFromTime,
      utcToTime,
    });

  if (!isSuccess || !data) return Promise.reject();

  return data;
}
