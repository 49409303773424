import {
  IMeasurement,
  ISensorDataSeries,
  ITemporalValue,
  TemporalDataSeries,
} from 'app/business-logic/domain-models/Monitoring';
import * as MonitorService from 'app/business-logic/services/monitor-service/index';
import { LogManager } from 'core/logging/LogManager';
import Guid from 'core/types/Guid';
import LocalTime, { ITimeRangeMillis } from 'core/types/LocalTime';
import { DateTime } from 'luxon';

import cache, { CacheEntry } from './SensorDataCache';
import SensorDataChunk from './SensorDataChunk';
import { isStale } from '../monitor-service/helpers/isStale';

const logger = LogManager.getLogger('SensorDataManager');

interface ISensorDataSeriesFetchResult {
  timeRange: ITimeRangeMillis;
  dataSeries: ISensorDataSeries;
  error?: Error;
}

export enum CacheStrategy {
  cacheOnly,
  cacheThenNetwork,
}

const defaultExport = {
  getSpotDataForSensors,
  getSpotData,
  getSensorData,
  preloadSensorGroupData,
  clearCache,
};

export const SensorDataManager = {
  ...defaultExport,
};

export default defaultExport;

async function getSpotDataForSensors(
  sensorIds: Guid[],
  spotTimeMillis: number,
  cacheStrategy = CacheStrategy.cacheThenNetwork
): Promise<IMeasurement[] | null> {
  if (!(sensorIds && sensorIds.length && spotTimeMillis)) {
    return null;
  }

  const result: IMeasurement[] = [];

  const sensorsToFetch: Guid[] = [];
  const sensorsWithCachedData = new Map<Guid, SensorDataChunk>();

  await Promise.all(
    sensorIds.map(async sensorId => {
      const cacheResult = await getSpotDataFromCache(
        sensorId,
        spotTimeMillis,
        cacheStrategy === CacheStrategy.cacheOnly
      );
      if (cacheResult) {
        if (cacheResult instanceof SensorDataChunk) {
          sensorsToFetch.push(sensorId);
          sensorsWithCachedData.set(sensorId, cacheResult);
        } else {
          result.push(cacheResult);
        }
      } else {
        sensorsToFetch.push(sensorId);
      }
    })
  );

  if (sensorsToFetch.length && cacheStrategy !== CacheStrategy.cacheOnly) {
    let sensorIdList = [...sensorsToFetch]
      .sort((left, right) => left.localeCompare(right))
      .slice(0, 2)
      .join(', ');

    if (sensorsToFetch.length > 2) {
      sensorIdList += '...';
    }

    logger.debug(`Spot data cache -> hit#: ${result.length}, missed#: ${sensorsToFetch.length} - ${sensorIdList}`);

    const remainingData = await MonitorService.fetchSpotDataForSensors(
      sensorsToFetch,
      DateTime.fromMillis(spotTimeMillis)
    );
    remainingData.forEach(dataPoint => {
      result.push(dataPoint);

      const chunk = sensorsWithCachedData.get(dataPoint.sensorId);
      if (chunk && typeof dataPoint.value === 'number') {
        chunk.unshift(dataPoint.timeMillis, dataPoint.value);
      }
    });
  }

  return result;
}

async function getSpotData(
  sensorId: Guid,
  spotTimeMillis: number,
  cacheStrategy: CacheStrategy
): Promise<IMeasurement | null> {
  if (!(sensorId && spotTimeMillis)) return null;

  const cachedDataResult = await getSpotDataFromCache(
    sensorId,
    spotTimeMillis,
    cacheStrategy === CacheStrategy.cacheOnly
  );
  const chunk = cachedDataResult !== null && cachedDataResult instanceof SensorDataChunk && cachedDataResult;

  if (cachedDataResult !== null && chunk === null) {
    return cachedDataResult as IMeasurement;
  }

  if (cacheStrategy === CacheStrategy.cacheOnly) return null;

  const spotData = await MonitorService.fetchSpotDataForSensors([sensorId], DateTime.fromMillis(spotTimeMillis));
  if (!(spotData && spotData.length)) return null;

  const [spotDataPoint] = spotData;

  if (chunk && typeof spotDataPoint?.value === 'number') {
    chunk.unshift(spotDataPoint.timeMillis, spotDataPoint.value);
  }

  if (!spotDataPoint) return null;

  return spotDataPoint;
}

async function getSpotDataFromCache(
  sensorId: Guid,
  spotTimeMillis: number,
  useClosest: boolean
): Promise<IMeasurement | SensorDataChunk | null> {
  if (!(sensorId && spotTimeMillis)) return null;

  const entry = cache.get(sensorId, false);
  if (!entry) return null;

  await entry.lock;

  const dataPoint = getSpotDataPoint() || getClosestSpotDataPoint();
  if (!dataPoint) return null;

  return {
    sensorId,
    isStale: isStale({
      dataTimeMillis: dataPoint.timeMillis,
      facilityTimeMillis: spotTimeMillis,
      staleDataTimeoutSeconds: entry.staleDataTimeoutMillis,
    }),
    ...dataPoint,
  };

  ////////////////////

  function getSpotDataPoint(): ITemporalValue | null {
    for (const chunk of entry?.chunks ?? []) {
      if (spotTimeMillis < chunk.startMillis || spotTimeMillis > chunk.endMillis) {
        continue;
      }

      const spotDataIndex = getIndexOfClosestTime(chunk.data, spotTimeMillis);

      if (spotDataIndex === null) continue;

      const [timeMillis, value] = chunk.data[spotDataIndex] ?? ([] as number[]);

      if (typeof timeMillis !== 'number' || typeof value !== 'number') continue;

      // If the time for the data point that we got back from the chunk is after our spot time, it means that
      // the chunk probably spans over sampling rate intervals and should be extended backwards. For example,
      // if we have a 10 minute sampling rate, and we are looking at a chunk that goes from 10:15 - 11:15, and
      // we ask for the value at 10:18, the chunk would give us back the value at 10:20 which is its first entry.
      // In this example, we need to go and fetch the spot data point for 10:18 from the server - this will
      // (ideally) give us back the 10:10 point, which we can add to this chunk.
      if (timeMillis > spotTimeMillis) continue;

      // Otherwise, this is the the data point to return.
      return { timeMillis, value };
    }
    return null;
  }

  function getClosestSpotDataPoint(): ITemporalValue | null {
    if (!useClosest) return null;

    let latestChunkBeforeSpotTime: SensorDataChunk | null = null;

    for (const chunk of entry?.chunks ?? []) {
      // We know that there are no chunks that span over the spot time (otherwise we would have found a better spot
      // data point already) so if we find a chunk that starts on/after the spot time then we can stop looking.
      if (chunk.startMillis > spotTimeMillis) break;
      latestChunkBeforeSpotTime = chunk;
    }

    if (!latestChunkBeforeSpotTime) return null;

    const lastDataPointInChunk = latestChunkBeforeSpotTime.data[latestChunkBeforeSpotTime.data.length - 1];
    const [timeMillis, value] = lastDataPointInChunk ?? ([] as number[]);

    if (typeof timeMillis !== 'number' || typeof value !== 'number') return null;

    return { timeMillis, value };
  }
}
/**
 * @deprecated Use fetchSensorDataSeries instead
 */
async function getSensorData(
  sensorId: Guid,
  startMillis: number,
  endMillis: number,
  cacheStrategy = CacheStrategy.cacheThenNetwork
): Promise<TemporalDataSeries | null> {
  if (!(sensorId && startMillis && endMillis)) {
    return null;
  }

  if (cacheStrategy !== CacheStrategy.cacheOnly) {
    await preloadSensorData(sensorId, startMillis, endMillis);
  }

  return getDataFromCache(sensorId, startMillis, endMillis);
}

async function preloadSensorData(sensorId: Guid, startMillis: number, endMillis: number): Promise<void> {
  if (!(sensorId && startMillis && endMillis)) {
    return;
  }

  const entry = cache.get(sensorId) ?? { lock: null };
  await entry?.lock;

  const fetchSensorDataLock = async () => {
    const dataGaps = calculateDataGaps(sensorId, startMillis, endMillis);

    if (dataGaps.length) {
      const fetchedData = await fetchSensorData(sensorId, dataGaps);
      mergeChunks(sensorId, fetchedData);
    }
  };

  return (entry.lock = fetchSensorDataLock());
}

async function preloadSensorGroupData(
  sensorGroupId: Guid,
  startMillis: number,
  endMillis: number
): Promise<void[] | void> {
  if (!(sensorGroupId && startMillis && endMillis)) {
    return;
  }

  const sensorGroupData = await fetchSensorGroupData(sensorGroupId, {
    startMillis,
    endMillis,
  });
  const chunkMergers: Promise<void>[] = [];

  for (const sensor of sensorGroupData) {
    const sensorId = sensor[0];
    const sensorData = sensor[1];

    const entry = cache.get(sensorId) ?? { lock: null };

    const entryMerger = async () => {
      await entry?.lock;
      mergeChunks(sensorId, [sensorData]);
    };

    chunkMergers.push((entry.lock = entryMerger()));
  }

  return await Promise.all(chunkMergers);
}

////////////////////

function calculateDataGaps(sensorId: Guid, startMillis: number, endMillis: number): ITimeRangeMillis[] {
  const entry = cache.get(sensorId);
  let queries: ITimeRangeMillis[] = [{ startMillis, endMillis }];

  // Failsafe for over-complexity
  let iterationCount = 0;

  for (let haveQueriesChanged = true; haveQueriesChanged && iterationCount < 10; iterationCount++) {
    const processedQueries: ITimeRangeMillis[] = [];
    haveQueriesChanged = false;

    queries.forEach(query => {
      const modifiedQueries = modifyQueryForExistingChunks(query, entry?.chunks ?? []);
      if (!modifiedQueries) {
        processedQueries.push(query);
        return;
      }

      haveQueriesChanged = true;
      Array.prototype.push.apply(processedQueries, modifiedQueries);
    });

    queries = processedQueries;
  }

  return iterationCount < 10 ? queries : [{ startMillis, endMillis }];
}

function modifyQueryForExistingChunks(query: ITimeRangeMillis, chunks: SensorDataChunk[]): ITimeRangeMillis[] | null {
  const { startMillis, endMillis } = query;

  for (const chunk of chunks) {
    // The query is entirely within this chunk so we don't need to query it.
    if (startMillis >= chunk.startMillis && endMillis <= chunk.endMillis) {
      return [];
    }

    // The query extends over the chunk
    if (startMillis < chunk.startMillis && endMillis > chunk.endMillis) {
      return [
        { startMillis, endMillis: chunk.startMillis },
        { startMillis: chunk.endMillis, endMillis },
      ];
    }

    // The query overlaps the chunk
    if (startMillis < chunk.endMillis && endMillis > chunk.startMillis) {
      // The query overlaps the start of the chunk
      if (startMillis < chunk.startMillis) {
        return [{ startMillis, endMillis: chunk.startMillis }];
      }

      // The query overlaps the end of the chunk
      return [{ startMillis: chunk.endMillis, endMillis }];
    }
  }

  // If we got through all the chunks then the query range must not overlap anything - so the query doesn't
  // need to be modified.
  return null;
}

async function fetchSensorData(
  sensorId: Guid,
  queryTimeRanges: ITimeRangeMillis[]
): Promise<ISensorDataSeriesFetchResult[]> {
  const queries = queryTimeRanges.map(async timeRange => {
    const startTime = DateTime.fromMillis(timeRange.startMillis);
    const endTime = DateTime.fromMillis(timeRange.endMillis);

    logger.debug(`Querying data gap for sensor ${sensorId} from ${startTime} to ${endTime}.`);

    try {
      const sensorDataSeries = await MonitorService.fetchSensorDataSeries([sensorId], startTime, endTime);

      return {
        timeRange,
        dataSeries: sensorDataSeries.length ? sensorDataSeries[0] : createEmptySensorDataSeries(sensorId),
      };
    } catch (error) {
      logger.warn(
        `Could not retrieve sensor data for sensor ${sensorId} between ${startTime} and ${endTime}: ${error}`
      );

      return {
        timeRange,
        dataSeries: createEmptySensorDataSeries(sensorId),
        error,
      };
    }
  });

  return (await Promise.all(queries)) as ISensorDataSeriesFetchResult[];
}

async function fetchSensorGroupData(
  sensorGroupId: Guid,
  timeRange: ITimeRangeMillis
): Promise<Map<Guid, ISensorDataSeriesFetchResult>> {
  const result = new Map<Guid, ISensorDataSeriesFetchResult>();

  const startTime = LocalTime.fromMillis(timeRange.startMillis);
  const endTime = LocalTime.fromMillis(timeRange.endMillis);

  logger.debug(`Querying data range for sensor group ${sensorGroupId} from ${startTime} to ${endTime}.`);

  try {
    const sensorDataSeries = await MonitorService.fetchSensorDataForSensorGroup(sensorGroupId, startTime, endTime);
    for (const series of sensorDataSeries) {
      result.set(series.sensorId ?? '', { timeRange, dataSeries: series });
    }
  } catch (error) {
    logger.warn(
      `Could not retrieve sensor data for sensor group ${sensorGroupId} between ${startTime} and ${endTime}: ${error}`
    );
  }

  return result;
}

function createEmptySensorDataSeries(sensorId: Guid): ISensorDataSeries {
  return {
    sensorId,
    staleDataTimeoutSeconds: 0,
    data: [],
  };
}

// TODO: 27/05/2019 - gerrod.thomas - Consider moving this to a web worker
// Wrap the packets in an ArrayBuffer and use Comlink - https://github.com/GoogleChromeLabs/comlink
function mergeChunks(sensorId: Guid, fetchedData: ISensorDataSeriesFetchResult[]) {
  if (!fetchedData.length) return;

  const entry = cache.get(sensorId, false);

  if (!entry) return;

  updateStaleDataTimeout(entry, fetchedData);

  let chunks = [...entry.chunks];

  // Create a new chunk for each valid data series that we found.
  fetchedData.forEach(({ timeRange, dataSeries, error }) => {
    const { data } = dataSeries;

    // If we didn't get an error querying for this series, but we also didn't get any data, then ignore it -
    // we will re-query if we get a subsequent request for the same period of data.
    if (!error && !data.length) return;

    const chunk = new SensorDataChunk(data);
    const endMillis = data[data.length - 1]?.[0];

    if (typeof endMillis !== 'number') return;

    chunk.startMillis = timeRange.startMillis;
    chunk.endMillis = !error ? endMillis : timeRange.endMillis;

    chunks.push(chunk);
  });

  // Nothing more to do if we didn't add any new chunks.
  if (chunks.length === entry.chunks.length) return;

  // Sort the chunks so we can work out which ones we can merge.
  chunks.sort((left, right) => left.startMillis - right.startMillis);

  // Keep on looping through the chunks until we get through a whole loop without modifying the number
  // of chunks that we have - this indicates that we've merged the chunks as much as possible.
  for (let chunkCount = 0; chunks.length > 1 && chunkCount < chunks.length; chunkCount = chunks.length) {
    chunks = chunks.reduce((memo, chunk) => {
      if (!memo.length) {
        memo.push(chunk);
      } else {
        const prevChunk = memo[memo.length - 1];
        if (prevChunk?.canMerge(chunk)) {
          prevChunk.merge(chunk);
        } else {
          memo.push(chunk);
        }
      }

      return memo;
    }, [] as SensorDataChunk[]);
  }

  entry.chunks = chunks;
}

function getDataFromCache(sensorId: Guid, startMillis: number, endMillis: number): TemporalDataSeries {
  const entry = cache.get(sensorId);
  const data: TemporalDataSeries = [];

  if (!entry) return [];

  for (const chunk of entry.chunks) {
    if (startMillis > chunk.endMillis || endMillis < chunk.startMillis) {
      continue;
    }

    const startIndex = startMillis < chunk.startMillis ? 0 : getIndexOfClosestTime(chunk.data, startMillis);

    if (typeof startIndex !== 'number') {
      console.error('Error finding startIndex');
      return [];
    }

    const endIndex =
      endMillis > chunk.endMillis ? chunk.data.length - 1 : getIndexOfClosestTime(chunk.data, endMillis, 1, startIndex);

    if (typeof endIndex !== 'number') {
      console.error('Error finding endIndex');
      return [];
    }

    const dataFromChunk = chunk.data.slice(startIndex, endIndex + 1);
    Array.prototype.push.apply(data, dataFromChunk);
  }

  return data;
}

function updateStaleDataTimeout(entry: CacheEntry | null, fetchedData: ISensorDataSeriesFetchResult[]) {
  if (!entry || entry?.staleDataTimeoutMillis) return;

  for (const fetchResult of fetchedData) {
    if (fetchResult.dataSeries.staleDataTimeoutSeconds) {
      entry.staleDataTimeoutMillis = fetchResult.dataSeries.staleDataTimeoutSeconds * 1000;
      return;
    }
  }
}

/**
 * Binary search to find the index of the data point whose time is closest to the target time.
 */
function getIndexOfClosestTime(data: TemporalDataSeries, targetTime: number, resolution = -1, startIndex = 0) {
  let start = startIndex,
    end = data.length - 1,
    midpoint: number | null = null;

  while (start <= end) {
    midpoint = Math.floor((start + end) / 2);

    const time = data[midpoint]?.[0];

    if (time === targetTime) return midpoint;

    if (typeof time === 'number' && time < targetTime) {
      start = midpoint + 1;
    } else {
      end = midpoint - 1;
    }
  }

  // If we get this far, we couldn't find the exact time, so the midpoint represents the closest possible time. Use
  // the 'resolution' parameter to work out if we should return the time before (-1) or after (1) the requested time.
  if (resolution) {
    if (typeof midpoint !== 'number') {
      console.error('Error finding midpoint');
      return null;
    }

    const midpointTime = data[midpoint];

    if (typeof midpointTime === 'number') {
      if (resolution < 0 && midpointTime > targetTime && midpoint > startIndex) return midpoint - 1;
      if (resolution > 0 && midpointTime < targetTime && midpoint < data.length - 1) return midpoint + 1;
    }
  }

  return midpoint;
}

function clearCache() {
  cache.clear();
}
