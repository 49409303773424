import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';
import { AlertPointReport } from './types';

export async function fetchAlertPointReportsData() {
  const { data, isSuccess }: HttpResponse<AlertPointReport[]> = await httpClient.useInternalApi
    .asAuthorised()
    .get('reports/alert-points');

  if (!isSuccess || !data) return Promise.reject();

  return data;
}
