import { ReactComponent as AirHorn } from 'app/visuals/images/icons/noise-classification/air-horn.svg';
import { ReactComponent as AirTraffic } from 'app/visuals/images/icons/noise-classification/air-traffic.svg';
import { ReactComponent as Airport } from 'app/visuals/images/icons/noise-classification/airport.svg';
import { ReactComponent as Alarm } from 'app/visuals/images/icons/noise-classification/alarm.svg';
import { ReactComponent as Alert } from 'app/visuals/images/icons/noise-classification/alert.svg';
import { ReactComponent as AngleGrinder } from 'app/visuals/images/icons/noise-classification/angle-grinder.svg';
import { ReactComponent as Atv } from 'app/visuals/images/icons/noise-classification/atv.svg';
import { ReactComponent as BackgroundNoise } from 'app/visuals/images/icons/noise-classification/background-noise.svg';
import { ReactComponent as BeltNoise } from 'app/visuals/images/icons/noise-classification/belt-noise.svg';
import { ReactComponent as Bike } from 'app/visuals/images/icons/noise-classification/bike.svg';
import { ReactComponent as Bird } from 'app/visuals/images/icons/noise-classification/bird.svg';
import { ReactComponent as Blasting } from 'app/visuals/images/icons/noise-classification/blasting.svg';
import { ReactComponent as Bus } from 'app/visuals/images/icons/noise-classification/bus.svg';
import { ReactComponent as CafeteriaNoise } from 'app/visuals/images/icons/noise-classification/cafeteria-noise.svg';
import { ReactComponent as Calibrations } from 'app/visuals/images/icons/noise-classification/calibrations.svg';
import { ReactComponent as CallToPrayer } from 'app/visuals/images/icons/noise-classification/call-to-prayer.svg';
import { ReactComponent as Car } from 'app/visuals/images/icons/noise-classification/car.svg';
import { ReactComponent as Cleaning } from 'app/visuals/images/icons/noise-classification/cleaning.svg';
import { ReactComponent as CoffeeMachine } from 'app/visuals/images/icons/noise-classification/coffee-machine.svg';
import { ReactComponent as Community } from 'app/visuals/images/icons/noise-classification/community.svg';
import { ReactComponent as Compactor } from 'app/visuals/images/icons/noise-classification/compactor.svg';
import { ReactComponent as ConstructionVehicle } from 'app/visuals/images/icons/noise-classification/construction-vehicle.svg';
import { ReactComponent as Construction } from 'app/visuals/images/icons/noise-classification/construction.svg';
import { ReactComponent as Crane } from 'app/visuals/images/icons/noise-classification/crane.svg';
import { ReactComponent as CrowdNoise } from 'app/visuals/images/icons/noise-classification/crowd-noise.svg';
import { ReactComponent as Dog } from 'app/visuals/images/icons/noise-classification/dog.svg';
import { ReactComponent as DoorOpeningClosing } from 'app/visuals/images/icons/noise-classification/door-opening-closing.svg';
import { ReactComponent as Drilling } from 'app/visuals/images/icons/noise-classification/drilling.svg';
import { ReactComponent as Drone } from 'app/visuals/images/icons/noise-classification/drone.svg';
import { ReactComponent as Entertainment } from 'app/visuals/images/icons/noise-classification/entertainment.svg';
import { ReactComponent as Evacuation } from 'app/visuals/images/icons/noise-classification/evacuation.svg';
import { ReactComponent as Excavator } from 'app/visuals/images/icons/noise-classification/excavator.svg';
import { ReactComponent as FanNoise } from 'app/visuals/images/icons/noise-classification/fan-noise.svg';
import { ReactComponent as FarmAnimals } from 'app/visuals/images/icons/noise-classification/farm-animals.svg';
import { ReactComponent as Farm } from 'app/visuals/images/icons/noise-classification/farm.svg';
import { ReactComponent as FireArms } from 'app/visuals/images/icons/noise-classification/fire-arms.svg';
import { ReactComponent as Fires } from 'app/visuals/images/icons/noise-classification/fires.svg';
import { ReactComponent as Fireworks } from 'app/visuals/images/icons/noise-classification/fireworks.svg';
import { ReactComponent as Footsteps } from 'app/visuals/images/icons/noise-classification/footsteps.svg';
import { ReactComponent as Forklift } from 'app/visuals/images/icons/noise-classification/forklift.svg';
import { ReactComponent as Frogs } from 'app/visuals/images/icons/noise-classification/frogs.svg';
import { ReactComponent as FrontEndLoader } from 'app/visuals/images/icons/noise-classification/front-end-loader.svg';
import { ReactComponent as Hail } from 'app/visuals/images/icons/noise-classification/hail.svg';
import { ReactComponent as Hammering } from 'app/visuals/images/icons/noise-classification/hammering.svg';
import { ReactComponent as HarbourNoise } from 'app/visuals/images/icons/noise-classification/harbour-noise.svg';
import { ReactComponent as Harbour } from 'app/visuals/images/icons/noise-classification/harbour.svg';
import { ReactComponent as HaulTruck } from 'app/visuals/images/icons/noise-classification/haul-truck.svg';
import { ReactComponent as Helicopter } from 'app/visuals/images/icons/noise-classification/helicopter.svg';
import { ReactComponent as HumanActivity } from 'app/visuals/images/icons/noise-classification/human-activity.svg';
import { ReactComponent as Insects } from 'app/visuals/images/icons/noise-classification/insects.svg';
import { ReactComponent as Jackhammer } from 'app/visuals/images/icons/noise-classification/jackhammer.svg';
import { ReactComponent as LandscapingEquipment } from 'app/visuals/images/icons/noise-classification/landscaping-equipment.svg';
import { ReactComponent as LargeCar } from 'app/visuals/images/icons/noise-classification/large-car.svg';
import { ReactComponent as LawnMower } from 'app/visuals/images/icons/noise-classification/lawn-mower.svg';
import { ReactComponent as LoudExhaust } from 'app/visuals/images/icons/noise-classification/loud-exhaust.svg';
import { ReactComponent as MaterialHandling } from 'app/visuals/images/icons/noise-classification/material-handling.svg';
import { ReactComponent as Microphone } from 'app/visuals/images/icons/noise-classification/microphone.svg';
import { ReactComponent as Milling } from 'app/visuals/images/icons/noise-classification/milling.svg';
import { ReactComponent as Mining } from 'app/visuals/images/icons/noise-classification/mining.svg';
import { ReactComponent as Motorbike } from 'app/visuals/images/icons/noise-classification/motorbike.svg';
import { ReactComponent as MultipleUsersWifi } from 'app/visuals/images/icons/noise-classification/multiple-users-wifi.svg';
import { ReactComponent as Music } from 'app/visuals/images/icons/noise-classification/music.svg';
import { ReactComponent as NeighbouringSiteNoise } from 'app/visuals/images/icons/noise-classification/neighbouring-site-noise.svg';
import { ReactComponent as Neighbours } from 'app/visuals/images/icons/noise-classification/neighbours.svg';
import { ReactComponent as NoAudio } from 'app/visuals/images/icons/noise-classification/no-audio.svg';
import { ReactComponent as OffsiteActivities } from 'app/visuals/images/icons/noise-classification/offsite-activities.svg';
import { ReactComponent as Other } from 'app/visuals/images/icons/noise-classification/other.svg';
import { ReactComponent as Pedestrians } from 'app/visuals/images/icons/noise-classification/pedestrians.svg';
import { ReactComponent as PileDriver } from 'app/visuals/images/icons/noise-classification/pile-driver.svg';
import { ReactComponent as Plane } from 'app/visuals/images/icons/noise-classification/plane.svg';
import { ReactComponent as Playground } from 'app/visuals/images/icons/noise-classification/playground.svg';
import { ReactComponent as PowerSaw } from 'app/visuals/images/icons/noise-classification/power-saw.svg';
import { ReactComponent as RaceCar } from 'app/visuals/images/icons/noise-classification/race-car.svg';
import { ReactComponent as Rain } from 'app/visuals/images/icons/noise-classification/rain.svg';
import { ReactComponent as ReversingSignal } from 'app/visuals/images/icons/noise-classification/reversing-signal.svg';
import { ReactComponent as Ring } from 'app/visuals/images/icons/noise-classification/ring.svg';
import { ReactComponent as RoadNoise } from 'app/visuals/images/icons/noise-classification/road-noise.svg';
import { ReactComponent as ShipContainer } from 'app/visuals/images/icons/noise-classification/ship-container.svg';
import { ReactComponent as ShipEngine } from 'app/visuals/images/icons/noise-classification/ship-engine.svg';
import { ReactComponent as ShipHorn } from 'app/visuals/images/icons/noise-classification/ship-horn.svg';
import { ReactComponent as Ship } from 'app/visuals/images/icons/noise-classification/ship.svg';
import { ReactComponent as ShippingContainer } from 'app/visuals/images/icons/noise-classification/shipping-container.svg';
import { ReactComponent as Siren1 } from 'app/visuals/images/icons/noise-classification/siren-1.svg';
import { ReactComponent as SirenAlarms } from 'app/visuals/images/icons/noise-classification/siren-alarms.svg';
import { ReactComponent as Siren } from 'app/visuals/images/icons/noise-classification/siren.svg';
import { ReactComponent as SiteActivities } from 'app/visuals/images/icons/noise-classification/site-activities.svg';
import { ReactComponent as Snowmobile } from 'app/visuals/images/icons/noise-classification/snowmobile.svg';
import { ReactComponent as Stapler } from 'app/visuals/images/icons/noise-classification/stapler.svg';
import { ReactComponent as Storm } from 'app/visuals/images/icons/noise-classification/storm.svg';
import { ReactComponent as Talking } from 'app/visuals/images/icons/noise-classification/talking.svg';
import { ReactComponent as Technician } from 'app/visuals/images/icons/noise-classification/technician.svg';
import { ReactComponent as Tractor } from 'app/visuals/images/icons/noise-classification/tractor.svg';
import { ReactComponent as Traffic } from 'app/visuals/images/icons/noise-classification/traffic.svg';
import { ReactComponent as Train } from 'app/visuals/images/icons/noise-classification/train.svg';
import { ReactComponent as Tram } from 'app/visuals/images/icons/noise-classification/tram.svg';
import { ReactComponent as TruckIdling } from 'app/visuals/images/icons/noise-classification/truck-idling.svg';
import { ReactComponent as Truck } from 'app/visuals/images/icons/noise-classification/truck.svg';
import { ReactComponent as VehicleBrakes } from 'app/visuals/images/icons/noise-classification/vehicle-brakes.svg';
import { ReactComponent as VehicleHorn } from 'app/visuals/images/icons/noise-classification/vehicle-horn.svg';
import { ReactComponent as Vehicles } from 'app/visuals/images/icons/noise-classification/vehicles.svg';
import { ReactComponent as Vent } from 'app/visuals/images/icons/noise-classification/vent.svg';
import { ReactComponent as WaterAlert } from 'app/visuals/images/icons/noise-classification/water-alert.svg';
import { ReactComponent as Weather } from 'app/visuals/images/icons/noise-classification/weather.svg';
import { ReactComponent as Whistle } from 'app/visuals/images/icons/noise-classification/whistle.svg';
import { ReactComponent as Wildlife } from 'app/visuals/images/icons/noise-classification/wildlife.svg';
import { ReactComponent as Wind } from 'app/visuals/images/icons/noise-classification/wind.svg';
import { ReactComponent as Omnis } from 'app/visuals/images/logo-symbol.svg';

export const NoiseClassificationIconMap = {
  'air-horn': AirHorn,
  'air-traffic': AirTraffic,
  aircraft: Plane,
  airport: Airport,
  alarm: Alarm,
  alarms: Alarm,
  alert: Alert,
  'angle-grinder-mini-cutter': AngleGrinder,
  animals: Wildlife,
  atv: Atv,
  'background-noise': BackgroundNoise,
  'belt-noise': BeltNoise,
  bike: Bike,
  bird: Bird,
  blasting: Blasting,
  bus: Bus,
  'cafeteria-noise': CafeteriaNoise,
  calibrations: Calibrations,
  'call-to-prayer': CallToPrayer,
  car: Car,
  cleaning: Cleaning,
  'coffee-machine': CoffeeMachine,
  community: Community,
  compactor: Compactor,
  construction: Construction,
  'construction-vehicle': ConstructionVehicle,
  crane: Crane,
  'crowd-noise': CrowdNoise,
  dog: Dog,
  'door-opening-closing': DoorOpeningClosing,
  drill: Drilling,
  drilling: Drilling,
  drone: Drone,
  'electric-hum': BackgroundNoise,
  entertainment: Entertainment,
  equipment: Drilling,
  evacuation: Evacuation,
  excavator: Excavator,
  'fan-noise': FanNoise,
  farm: Farm,
  'farm-animals': FarmAnimals,
  'fire-arms': FireArms,
  fires: Fires,
  fireworks: Fireworks,
  footsteps: Footsteps,
  forklift: Forklift,
  frogs: Frogs,
  'front-end-loader': FrontEndLoader,
  gardening: LawnMower,
  hail: Hail,
  hammering: Hammering,
  harbour: Harbour,
  'harbour-noise': HarbourNoise,
  'haul-truck': HaulTruck,
  helicopter: Helicopter,
  'human-activity': HumanActivity,
  'human-activity-header': HumanActivity,
  insects: Insects,
  jackhammer: Jackhammer,
  'landscaping-equipment': LandscapingEquipment,
  'large-car': LargeCar,
  'lawn-mower': LawnMower,
  'loud-exhaust': LoudExhaust,
  maintenance: Technician,
  'material-handling': MaterialHandling,
  microphone: Microphone,
  milling: Milling,
  mining: Mining,
  'mining-header': Mining,
  misc: Other,
  motorbike: Motorbike,
  'multiple-users-wifi': MultipleUsersWifi,
  music: Music,
  'neighbouring-site-noise': NeighbouringSiteNoise,
  neighbours: Neighbours,
  'no-audio': NoAudio,
  'non-construction': OffsiteActivities,
  'offsite-activities': OffsiteActivities,
  omnis: Omnis,
  other: Other,
  'other-vehicle': Vehicles,
  pedestrians: Pedestrians,
  'pile-driver': PileDriver,
  plane: Plane,
  playground: Playground,
  'power-saw': PowerSaw,
  'race-car': RaceCar,
  rain: Rain,
  'reversing-signal': ReversingSignal,
  ring: Ring,
  'road-noise': RoadNoise,
  ship: Ship,
  'ship-container': ShipContainer,
  'ship-engine': ShipEngine,
  'ship-horn': ShipHorn,
  'shipping-container': ShippingContainer,
  siren: Siren,
  'siren-1': Siren1,
  'siren-alarms': SirenAlarms,
  'siren-vehicles': Siren,
  'site-activities': SiteActivities,
  snowmobile: Snowmobile,
  'standard-alert': Alert,
  stapler: Stapler,
  storm: Storm,
  talking: Talking,
  technician: Technician,
  tractor: Tractor,
  traffic: Traffic,
  train: Train,
  'train-bogey-squeal-screech': Train,
  'train-idle': Train,
  'train-operations-whistle': Train,
  'train-shunt': Train,
  tram: Tram,
  truck: Truck,
  'truck-idling': TruckIdling,
  'vehicle-brakes': VehicleBrakes,
  'vehicle-horn': VehicleHorn,
  vehicles: Vehicles,
  vent: Vent,
  'water-alert': WaterAlert,
  weather: Weather,
  'weather-header': Weather,
  whistle: Whistle,
  wildlife: Wildlife,
  wind: Wind,
} as const;
