import { gql } from '@apollo/client';

export const FETCH_ALERT_CONFIG = gql`
  query FetchAlertConfig($fetchAlertConfigFilter: GuidIdInFilter) {
    facility {
      alerts {
        alertScriptConfigurations(filter: $fetchAlertConfigFilter) {
          name
          traceWindowLeadInSeconds
          traceWindowLeadOutSeconds
          omnisLocationId
          omnisDataType
          variables {
            variable {
              displayUnit {
                name
              }
              thresholds {
                valueInDisplayUnit
                hexColor
              }
            }
          }
        }
      }
    }
  }
`;
