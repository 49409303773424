import Guid from 'core/types/Guid';
import { FETCH_ALERT_CONFIG } from './graphql/facility.fetchAlertConfig';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { useMemo } from 'react';
import { NoiseMeasurementType } from 'app/views/components/real-time-noise-connection/RealTimeNoiseConnection.types';
import { ApolloQueryResult } from '@apollo/client';
import { FetchAlertConfigQuery, FetchAlertConfigQueryVariables } from 'app/__generated__/global';

export type AlertConfig = {
  name: string;
  traceWindowLeadInSeconds?: number | null;
  traceWindowLeadOutSeconds?: number | null;
  omnisLocationId?: number | null;
  omnisDataType: NoiseMeasurementType | null;
  displayUnit?: string;
  thresholds?: {
    valueInDisplayUnit: number;
    hexColor: string;
  }[];
};

export const useAlertConfiguration = (alertConfigId: Guid | undefined) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEY.alertConfiguration, alertConfigId],
    queryFn: () => {
      if (!alertConfigId) return;
      return ApolloProvider.global().query<FetchAlertConfigQuery, FetchAlertConfigQueryVariables>({
        query: FETCH_ALERT_CONFIG,
        variables: {
          fetchAlertConfigFilter: {
            id_in: [alertConfigId],
          },
        },
      });
    },
    enabled: !!alertConfigId,
  });
  return useMemo(
    () => ({
      data: extractConfig(data),
      ...rest,
    }),
    [data, rest]
  );
};

function extractConfig(response?: ApolloQueryResult<FetchAlertConfigQuery>): AlertConfig | undefined {
  if (!response) return;
  const [config] = response.data.facility?.alerts?.alertScriptConfigurations ?? [];
  if (!config) return;
  const { __typename, omnisDataType, variables, ...rest } = config;
  const [variable] = variables ?? [];
  const thresholds = variable?.variable.thresholds.map(threshold => {
    const { __typename, ...rest } = threshold;
    return rest;
  });
  return {
    ...rest,
    omnisDataType: (omnisDataType as NoiseMeasurementType) ?? null,
    displayUnit: variable?.variable.displayUnit?.name,
    thresholds,
  };
}
