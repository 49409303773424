import { useAppDispatch } from 'app/redux/store';
import { closeDrawer } from 'app/redux/ui/actions';
import { ReactComponent as CloseIcon } from 'app/visuals/images/icons/cross.svg';
import { useI18n } from 'core/hooks/useI18n';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  SidebarCloseButton,
  SidebarContainer,
  SidebarHeader,
  SidebarTitle,
} from 'app/views/components/right-drawer/RightDrawer.styles';
import { OverpressureCard, PathCard, PollutantCard, GroundVibrationCard, FlyrockCard } from './scenario-data-cards';

export const ScenarioDataDrawerContent = () => {
  const { l10n } = useI18n('app/views/blasting', 'page.viewScenario.rightDrawer');
  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    dispatch(closeDrawer('SCENARIO_DATA'));
  };

  useCloseDrawerWhenNoAlias();

  return (
    <>
      <SidebarHeader>
        <SidebarCloseButton title={l10n('close')} data-testid="scenario-data-drawer-close" onClick={handleOnClick}>
          <CloseIcon />
        </SidebarCloseButton>
        <SidebarTitle>{l10n('title')}</SidebarTitle>
      </SidebarHeader>
      <SidebarContainer>
        <PathCard />
        <PollutantCard />
        <FlyrockCard />
        <GroundVibrationCard />
        <OverpressureCard />
      </SidebarContainer>
    </>
  );
};

const useCloseDrawerWhenNoAlias = () => {
  const { alias } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!alias) {
      dispatch(closeDrawer('SCENARIO_DATA'));
    }
  }, [alias, dispatch]);
};
