import { LatLngLiteral } from 'app/business-logic/domain-models/Facility';
import { GisModels } from 'app/business-logic/services/model-service';
import { IModellingInterval } from 'app/business-logic/services/modelling-service/types';
import { INamedEntity } from 'app/business-logic/services/types';
import { Job } from 'app/redux/jobs/types';
import Guid from 'core/types/Guid';
import { DateTime } from 'luxon';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchAlertPointData } from './fetchAlertPointData';
import { fetchAlertPointReports } from './fetchAlertPointReports';
import { fetchEmissionSources } from './fetchEmissionSources';
import { fetchModelData } from './fetchModelData';
import {
  fetchAlertPointDataFulfilled,
  fetchAlertPointDataPending,
  fetchAlertPointReportsFulfilled,
  fetchAlertPointReportsPending,
  fetchAlertPointReportsRejected,
  fetchModelDataFulfilled,
  fetchModelDataPending,
  fetchModelDataRejected,
} from './reducers';
import {
  fetchEmissionSourcesFulfilled,
  fetchEmissionSourcesPending,
  fetchEmissionSourcesRejected,
} from './reducers/emission-sources';
import { fetchAlertPointDataRejected } from './reducers/fetchAlertPointDataRejected';
import { AlertPoint, AlertPointReportsRequest, EmissionSourcesRequest, ModelRun } from './types';
import { resetStore } from '../actions';

export type ModellingState = {
  configuration: {
    activeModels: Guid[];
    autoUpdate: boolean;
    dateTime: number;
    emissionSources: boolean;
    modelFrom: number;
    models: INamedEntity[];
    modelTo: number;
    selectedIntervalIndex: number;
  };
  data: {
    alertPointReports: AlertPointReportsRequest;
    alertPoints: AlertPoint[];
    emissionSources: EmissionSourcesRequest;
    intervals: IModellingInterval[];
    models: ModelRun[];
  };
  map: {
    controls: {
      domainBoundaries: boolean;
    };
  };
};

export interface DataPoint {
  id: Guid;
  points: LatLngLiteral[];
}

export const INITIAL_STATE: ModellingState = {
  configuration: {
    activeModels: [],
    autoUpdate: false,
    dateTime: DateTime.now().startOf('hour').toMillis(),
    emissionSources: true,
    modelFrom: 0,
    models: [],
    modelTo: 0,
    selectedIntervalIndex: 0,
  },
  data: {
    alertPointReports: { status: 'NotRequested' },
    alertPoints: [],
    emissionSources: { status: 'NotRequested' },
    intervals: [],
    models: [],
  },
  map: {
    controls: {
      domainBoundaries: true,
    },
  },
};

export type Configuration = ModellingState['configuration'];

export const slice = createSlice({
  name: 'modelling',
  initialState: INITIAL_STATE,
  reducers: {
    setDomainBoundariesMapControl: (state, { payload }: PayloadAction<boolean>) => {
      state.map.controls.domainBoundaries = payload;
    },

    setConfiguration: (state, { payload }: PayloadAction<Partial<Configuration>>) => {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...payload,
        },
      };
    },

    toggleAutoUpdate: state => {
      state.configuration.autoUpdate = !state.configuration.autoUpdate;
    },

    modelsLoaded: (state, { payload }: PayloadAction<GisModels>) => {
      const models = Object.entries(payload).map(([id, name]) => ({ id, name }));
      state.configuration.models = models;
    },

    modelCreatedOrModified: (state, { payload }: PayloadAction<INamedEntity>) => {
      const isModifying = !!state.configuration.models.find(({ id }) => id === payload.id);
      if (isModifying) {
        state.configuration.models = state.configuration.models.map(model => {
          if (model.id !== payload.id) return model;
          return payload;
        });
      } else {
        state.configuration.models = [...state.configuration.models, payload];
      }
    },

    modelDeleted: (state, { payload }: PayloadAction<Guid>) => {
      if (payload.length) {
        state.configuration.models = state.configuration.models.filter(({ id }) => id !== payload);
      }
    },

    displayEmissionSourcesToggled: state => {
      state.configuration.emissionSources = !state.configuration.emissionSources;
    },

    /**
     * Sets the modelling time and rounds it to the nearest valid time,
     * based on the selected modelling interval, e.g. "10 minute" or "60 minute"
     */
    modellingTimeChanged: (
      state,
      { payload: { dateTime, intervalIndex } }: PayloadAction<{ dateTime: DateTime; intervalIndex?: number }>
    ) => {
      const selectedModellingInterval =
        state.data.intervals[
          typeof intervalIndex === 'number' ? intervalIndex : state.configuration.selectedIntervalIndex
        ]?.durationInMinutes ?? 60;
      const nearestMinute = Math.floor(dateTime.minute / selectedModellingInterval) * selectedModellingInterval;

      state.configuration.dateTime = dateTime.set({ minute: nearestMinute, second: 0, millisecond: 0 }).valueOf();
    },

    intervalsLoaded: (state, { payload }: PayloadAction<IModellingInterval[]>) => {
      state.data.intervals = payload;
      state.configuration.activeModels = [];
      state.data.alertPoints = [];
    },

    selectedIntervalChanged: (state, { payload }: PayloadAction<number>) => {
      state.configuration.selectedIntervalIndex = payload;
      state.configuration.activeModels = [];
      state.data.alertPoints = [];
    },

    toggleActiveModel: (state, { payload }: PayloadAction<Guid>) => {
      const shouldToggleOff = state.configuration.activeModels.includes(payload);
      if (shouldToggleOff) {
        state.configuration.activeModels = state.configuration.activeModels.filter(modelId => modelId !== payload);
      } else {
        state.configuration.activeModels = state.configuration.activeModels.concat(payload);
      }
    },

    modellingJobFailed: (state, { payload }: PayloadAction<Job>) => {
      state.data.models = state.data.models.map(model => {
        if (model.modelId !== payload.requesterId) return model;
        return {
          ...model,
          request: {
            ...model.request,
            status: 'Failure',
            requestId: payload.jobId,
          },
        };
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchModelData.pending, fetchModelDataPending)
      .addCase(fetchModelData.fulfilled, fetchModelDataFulfilled)
      .addCase(fetchModelData.rejected, fetchModelDataRejected);

    builder
      .addCase(fetchAlertPointData.pending, fetchAlertPointDataPending)
      .addCase(fetchAlertPointData.fulfilled, fetchAlertPointDataFulfilled)
      .addCase(fetchAlertPointData.rejected, fetchAlertPointDataRejected);

    builder
      .addCase(fetchAlertPointReports.pending, fetchAlertPointReportsPending)
      .addCase(fetchAlertPointReports.fulfilled, fetchAlertPointReportsFulfilled)
      .addCase(fetchAlertPointReports.rejected, fetchAlertPointReportsRejected);

    builder
      .addCase(fetchEmissionSources.pending, fetchEmissionSourcesPending)
      .addCase(fetchEmissionSources.fulfilled, fetchEmissionSourcesFulfilled)
      .addCase(fetchEmissionSources.rejected, fetchEmissionSourcesRejected);

    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
