import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';
import { OverlayFileInfo } from 'app/business-logic/domain-models/Blasting';

export const fetchTempOverlayFiles = async (): Promise<OverlayFileInfo[] | []> => {
  const { data, isSuccess }: HttpResponse<OverlayFileInfo[]> = await httpClient.useInternalApi
    .asAuthorised()
    .get('/configuration/blasting/scenarios/overlays');
  if (!isSuccess) throw new Error('Failed to fetch overlay files');
  if (!data) return [];

  return data;
};
