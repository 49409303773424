import { THEME } from 'app/theme/theme';
import { css } from 'styled-components';

/**
 * Breakpoints inspired by Material UI's breakpoints - https://mui.com/material-ui/customization/breakpoints/
 */

type Styles = ReturnType<typeof css>;

const mediaQuery = (query: string) => (styles: Styles) => {
  return `@media ${query} {
    ${styles
      .map(style => {
        if (typeof style === 'function') {
          // Required in order for theme access within supplied styles,
          // but problematic if we have other themes eventually...
          return style({ theme: THEME });
        }
        return style;
      })
      .join('')}
  }`;
};

/**
 * Matches screen widths greater than the screen size given by the breakpoint (inclusive)
 */
export const up =
  <Breakpoints extends Record<string, number>>(breakpoints: Breakpoints) =>
  (breakpoint: keyof Breakpoints | number, styles?: Styles) => {
    if (typeof breakpoint === 'string') {
      const query = `screen and (min-width: ${breakpoints[breakpoint]}px)`;
      if (!styles) return query;
      return mediaQuery(query)(styles);
    }
    if (typeof breakpoint === 'number') {
      const query = `screen and (min-width: ${breakpoint}px)`;
      if (!styles) return query;
      return mediaQuery(query)(styles);
    }
    throw new Error(`Unknown breakpoint: ${breakpoint.toString()}`);
  };

/**
 * Matches screen widths less than the screen size given by the breakpoint (exclusive)
 */
export const down =
  <Breakpoints extends Record<string, number>>(breakpoints: Breakpoints) =>
  (breakpoint: keyof Breakpoints | number, styles?: Styles) => {
    if (typeof breakpoint === 'string') {
      const breakpointValue = breakpoints[breakpoint];
      if (typeof breakpointValue !== 'number') throw new Error(`Unknown breakpoint: ${breakpoint}`);
      const query = `screen and (max-width: ${breakpointValue - 1}px)`;
      if (!styles) return query;
      return mediaQuery(query)(styles);
    }
    if (typeof breakpoint === 'number') {
      const query = `screen and (max-width: ${breakpoint - 1}px)`;
      if (!styles) return query;
      return mediaQuery(query)(styles);
    }
    throw new Error(`Invalid breakpoint: ${breakpoint.toString()}`);
  };

/**
 * Matches screen widths starting from the screen size given by the breakpoint (inclusive)
 * and stopping at the screen size of the breakpoint following it (exclusive)
 */
export const only =
  <Breakpoints extends Record<string, number>>(breakpoints: Breakpoints) =>
  (breakpoint: keyof Breakpoints, styles?: Styles) => {
    const breakpointEntries = Object.entries(breakpoints).sort(([, a], [, b]) => a - b);
    const breakpointIndex = breakpointEntries.findIndex(([key]) => key === breakpoint);
    if (breakpointIndex === -1) throw new Error(`Invalid breakpoint: ${breakpoint.toString()}`);
    const [maxKey] = breakpointEntries.find((_, i) => i === breakpointIndex + 1) ?? [];
    if (!maxKey) return up(breakpoints)(breakpoint, styles);
    const maxWidth = breakpoints[maxKey];
    if (typeof maxWidth !== 'number')
      throw new Error(`${maxKey} not in provided breakpoints:\n${JSON.stringify(breakpoints, null, 2)}`);
    const query = `screen and (min-width: ${breakpoints[breakpoint]}px) and (max-width: ${maxWidth - 1}px)`;
    if (!styles) return query;
    return mediaQuery(query)(styles);
  };

/**
 * Matches screen widths stopping at the screen size given by the breakpoint (exclusive)
 * and starting at the screen size of the breakpoint following it (inclusive)
 */
export const not =
  <Breakpoints extends Record<string, number>>(breakpoints: Breakpoints) =>
  (breakpoint: keyof Breakpoints, styles?: Styles) => {
    const breakpointEntries = Object.entries(breakpoints).sort(([, a], [, b]) => a - b);
    const breakpointIndex = breakpointEntries.findIndex(([key]) => key === breakpoint);
    if (breakpointIndex === -1) throw new Error(`Invalid breakpoint: ${breakpoint.toString()}`);
    const [maxKey] = breakpointEntries.find((_, i) => i === breakpointIndex + 1) ?? [];
    if (!maxKey) return down(breakpoints)(breakpoint, styles);
    const maxWidth: number | undefined = breakpoints[breakpoint];
    if (typeof maxWidth !== 'number')
      throw new Error(`${breakpoint.toString()} not in provided breakpoints:\n${JSON.stringify(breakpoints, null, 2)}`);
    const query = `screen and (max-width: ${maxWidth - 1}px) or (min-width: ${breakpoints[maxKey]}px)`;
    if (!styles) return query;
    return mediaQuery(query)(styles);
  };

/**
 * Matches screen widths greater than the screen size given by the breakpoint in the first argument (inclusive)
 * and less than the screen size given by the breakpoint key in the second argument (exclusive)
 */
export const between =
  <Breakpoints extends Record<string, number>>(breakpoints: Breakpoints) =>
  (start: keyof Breakpoints | number, end: keyof Breakpoints | number, styles?: Styles) => {
    if (typeof start === 'symbol') throw new Error(`Invalid breakpoint: ${start.toString}`);
    if (typeof end === 'symbol') throw new Error(`Invalid breakpoint: ${end.toString}`);
    const startValue: number | undefined = typeof start === 'string' ? breakpoints[start] : start;
    if (!startValue)
      throw new Error(`${start.toString()} not in provided in breakpoints:\n${JSON.stringify(breakpoints, null, 2)}`);
    const endValue: number | undefined = typeof end === 'string' ? breakpoints[end] : end;
    if (!endValue)
      throw new Error(`${end.toString()} not in provided in breakpoints:\n${JSON.stringify(breakpoints, null, 2)}`);
    const minBreakpoint = Math.min(startValue, endValue);
    const maxBreakpoint = Math.max(startValue, endValue);
    const query = `screen and (min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint - 1}px)`;
    if (!styles) return query;
    return mediaQuery(query)(styles);
  };
