import { CaseReducer } from '@reduxjs/toolkit';
import { fetchAlertPointData } from '../fetchAlertPointData';
import { ModellingState } from '../slice';

export const fetchAlertPointDataPending: CaseReducer<ModellingState, ReturnType<typeof fetchAlertPointData.pending>> = (
  state,
  {
    meta: {
      arg: { modelId, utcFromTime, utcToTime },
      requestId,
    },
  }
) => {
  return {
    ...state,
    data: {
      ...state.data,
      alertPoints: state.data.alertPoints
        .filter(point => {
          return modelId !== point.modelId;
        })
        .concat({
          modelId,
          utcFromTime,
          utcToTime,
          request: { status: 'Loading', requestId },
        }),
    },
  };
};
