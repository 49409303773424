import { gql } from '@apollo/client';

export const FETCH_FACILITY = gql`
  query FetchFacility {
    facility {
      facilityAlias: alias
      id
      defaultZoomLevel
      facilityCode
      ianaTimezone
      latitude
      longitude
      name
      region
      windowsTimezoneId: windowsTimezone
      ticketingSystemTenantId
      satelliteMapProvider
      roadMapProvider
      type
      smaxCustomerCode
      useImperialUnits
    }
    company {
      companyAlias: alias
      companyId: id
    }
  }
`;
