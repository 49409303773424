import { IRawKmlFileWithData } from '.';
import { IKmlFile, IRawKmlFile } from './types';

export const parseKmlFiles = (files: IRawKmlFile[]): IKmlFile[] => {
  const parser = new DOMParser();
  const filesWithData = files.filter(({ data }) => data) as IRawKmlFileWithData[];
  return filesWithData.map(({ name, data }) => ({
    name,
    data: parser.parseFromString(data, 'application/xml'),
  }));
};
