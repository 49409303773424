import { HandlesProps } from 'rc-slider/lib/Handles';
import { HandleProps } from 'rc-slider/lib/Handles/Handle';
import { useCallback, useRef, useState } from 'react';
import { Position, Tooltip } from '@blueprintjs/core';

import {
  SliderTooltipContent as TooltipContent,
  SliderWithTooltipHandle as Handle,
  SliderWithTooltipHandleInner as HandleInner,
} from './SliderWithTooltip.styles';
import { TipFormatter } from './SliderWithTooltip.types';

export const useHandleRender = (
  value: number | undefined,
  tipFormatter: TipFormatter | undefined
): HandlesProps['handleRender'] => {
  const handleRef = useRef<HTMLDivElement>(null);
  const [isHoveringHandle, setIsHoveringHandle] = useState(false);
  return useCallback(
    ({ props }: { props: HandleProps }) => {
      const { className } = props as HandleProps & { className?: string };
      const content = tipFormatter && typeof value === 'number' ? tipFormatter(value) : value?.toString();
      const isOpen = isHoveringHandle || className?.includes('rc-slider-handle-dragging');
      return (
        <>
          <Handle
            {...props}
            ref={handleRef}
            onMouseOver={() => setIsHoveringHandle(true)}
            onMouseOut={() => setIsHoveringHandle(false)}
          >
            <Tooltip
              content={<TooltipContent>{content}</TooltipContent>}
              isOpen={isOpen}
              position={Position.TOP}
              usePortal={false}
            >
              {/**
               * Need to wrap tooltip around an inner component so it's positioned
               * correctly, otherwise it remains at the handle's initial position
               * instead of following as the user drags it along
               */}
              <HandleInner />
            </Tooltip>
          </Handle>
        </>
      );
    },
    [isHoveringHandle, tipFormatter, value]
  );
};
