import { NoiseEventClassification } from 'app/business-logic/services/noise-service/noiseService.types';
import { SelectableButton } from 'app/components/selectable-button';
import { useClassificationIconSlugs } from 'app/views/alerts/hooks/use-classification-icon-slugs';
import { noiseClassificationSlugHasIcon } from 'app/views/configuration/pages/noise/noise-classifications/noise-classification-icon/noiseClassificationSlugHasIcon';
import Guid from 'core/types/Guid';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { EventClassificationState } from '../EventClassification';

type ClassificationButtonProps = {
  classification: NoiseEventClassification;
  isSelected: boolean;
  isLoading: boolean;
};

export const ClassificationButton = ({ classification, isSelected, isLoading }: ClassificationButtonProps) => {
  const {
    setFieldValue,
    values: { classifications },
  } = useFormikContext<EventClassificationState>();
  const selectClassification = ({ id, isSelected }: { id: Guid; isSelected: boolean }) => {
    if (isLoading) return;
    const field: keyof EventClassificationState = 'classifications';
    const value: EventClassificationState[typeof field] = classifications.map(c => {
      if (c.classification.id !== id) return c;
      return {
        ...c,
        isSelected,
      };
    });
    setFieldValue(field, value);
  };
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    selectClassification({ id: classification.id, isSelected: e.target.checked });
  };

  const [slug] = useClassificationIconSlugs({ categoryIds: [classification.categoryId] });
  const icon = noiseClassificationSlugHasIcon(slug) ? slug : 'omnis';
  return (
    <SelectableButton
      name={classification.name}
      isSelected={isSelected}
      isLoading={isLoading}
      onChange={handleOnChange}
      icon={icon}
    />
  );
};
