import { HANDLE_SIZE } from 'app/theme/global-styles/rc-slider-styles/rcSliderStyles';
import { styled } from 'styled-components';

export const SliderWithTooltipHandle = styled.div`
  z-index: ${({ theme }) => theme.zIndex.map};
`;

export const SliderWithTooltipHandleInner = styled.div`
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
`;

export const SliderTooltipContent = styled.span`
  white-space: nowrap;
`;
