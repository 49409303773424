import { css, styled } from 'styled-components';

export const FloatingContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.headerBar};
  max-height: 70%;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        height: 70%;
      `
    )}
`;

export const InnerContainer = styled.article<{ $offset: string }>`
  transform: translateY(${({ $offset }) => $offset});
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh; // Fallback for safety, in case --doc-height goes missing
  max-height: var(--doc-height);
`;

export const Handle = styled.header`
  appearance: none;
  border: 0;
  border-radius: ${({ theme: { spacing } }) => `${spacing(2)}px ${spacing(2)}px`} 0 0;
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Drawer = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  color: white;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
`;

export const HandleContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const HandleActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(0.5)}px;
`;
