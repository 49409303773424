import { ReactComponent as ArrowIcon } from 'app/visuals/images/icons/chevron-right.svg';
import { ReactComponent as CrossIcon } from 'app/visuals/images/icons/cross.svg';
import { useI18n } from 'core/hooks/useI18n';
import { MouseEvent, useCallback } from 'react';
import { Visibility } from '../DrawerWithHandle';
import { Close, ExpandCollapse } from './DrawerActions.styles';

type DrawerActionsProps = {
  drawerVisibility: Visibility;
  onExpandCollapse?: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  drawerActionsProps?: {
    expandCollapse?: React.HTMLAttributes<HTMLButtonElement>;
    close?: React.HTMLAttributes<HTMLButtonElement>;
  };
};

export const DrawerActions = ({
  drawerVisibility,
  onExpandCollapse,
  onClose,
  drawerActionsProps: { expandCollapse: expandCollapseProps = {}, close: closeProps = {} } = {},
}: DrawerActionsProps) => {
  const { l10n } = useI18n('app/components', 'actions');
  const handleMinimiseMaximise = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => onExpandCollapse?.(event),
    [onExpandCollapse]
  );
  const handleClose = useCallback((event: MouseEvent<HTMLButtonElement>) => onClose?.(event), [onClose]);

  if (drawerVisibility === 'hidden') return null;

  return (
    <>
      {onExpandCollapse && (
        <ExpandCollapse
          data-testid="drawer-content-with-handle-minimise-maximise-button"
          onClick={handleMinimiseMaximise}
          $drawerVisibility={drawerVisibility}
          title={l10n(drawerVisibility === 'visible' ? 'collapse' : 'expand')}
          aria-expanded={drawerVisibility === 'visible' ? true : false}
          {...expandCollapseProps}
        >
          <ArrowIcon />
        </ExpandCollapse>
      )}
      {onClose && (
        <Close
          data-testid="drawer-content-with-handle-close-button"
          onClick={handleClose}
          title={l10n('close')}
          {...closeProps}
        >
          <CrossIcon />
        </Close>
      )}
    </>
  );
};
