import { ButtonProps } from 'app/types';
import { styled, css } from 'styled-components';
import { PlaybackControlButtonIcon } from '..';
import { PlaybackControlButtonStatus } from './';

type StyledButtonProps = ButtonProps & {
  $status: PlaybackControlButtonStatus;
  $icon: PlaybackControlButtonIcon;
};

const activeStyles = css<StyledButtonProps>`
  ${({ $status, theme }) =>
    $status === PlaybackControlButtonStatus.active && `background-color: ${theme.colors.mono.ui05}`};
`;

const playIconStyles = css`
  svg {
    position: relative;
    left: 2px;
  }
`;

const playStyles = css<StyledButtonProps>`
  ${({ $icon }) => $icon === PlaybackControlButtonIcon.play && playIconStyles}
`;

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.mono.ui04};
  background-color: ${({ theme }) => theme.colors.mono.ui04};
  border-radius: 50%;
  &:hover {
    border-color: #fff;
  }
  ${activeStyles}
  ${playStyles}
  svg {
    fill: currentColor;
  }
`;
