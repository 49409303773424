import { useAppSelector } from 'app/redux/store';
import { useEffect } from 'react';

export const useHeapFacilityData = () => {
  const { companyAlias, facilityAlias, region, smaxCustomerCode, type } = useAppSelector(state => state.profile.facility);
  useEffect(() => {
    if (!window.heap) return;
    // To account for initial values before they're set
    if (!companyAlias || !facilityAlias) return;
    window.heap.addUserProperties({
      Company: companyAlias,
      Facility: facilityAlias,
      Region: region ?? 'UNKNOWN_REGION',
      SmaxCustomerCode: smaxCustomerCode ?? 'UNKNOWN_SMAX_CUSTOMER_CODE',
      FacilityType: type ?? 'UNKNOWN_FACILITY_TYPE'
    });
  }, [companyAlias, facilityAlias, region, smaxCustomerCode, type]);
};
