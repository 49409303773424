import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAlertPointReportsData } from 'app/business-logic/services/modelling-service/fetchAlertPointReportsData';
import { AlertPointReport } from 'app/business-logic/services/modelling-service/types';

import { IGlobalState } from '../reducer';

export const fetchAlertPointReports = createAsyncThunk<AlertPointReport[], undefined, { state: IGlobalState }>(
  'modelling/fetchAlertPointReports',
  async (_, { getState }) => {
    const {
      profile: {
        user: { accessControls },
      },
    } = getState();
    if (!accessControls['app.reports']) {
      console.warn('No permissions for app.reports, alert points cannot be requested');
      return [];
    }
    return await fetchAlertPointReportsData();
  }
);
