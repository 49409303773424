import { Classes } from '@blueprintjs/core';
import { NoiseClassificationIconMap } from 'app/views/configuration/pages/noise/noise-classifications/noise-classification-icon';
import { ChangeEvent } from 'react';
import {
  SelectableButtonIcon,
  SelectableButtonInner,
  SelectableButtonInput,
  SelectableButtonText,
  SelectableButtonWrapper,
} from './SelectableButton.styles';

type SelectableButtonProps = {
  name: string;
  disabled?: boolean;
  isSelected?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  icon?: keyof typeof NoiseClassificationIconMap;
  backgroundColor?: string;
  textColor?: string;
};

export const SelectableButton = ({
  name,
  isSelected,
  isLoading = false,
  icon,
  onChange,
  disabled = false,
  backgroundColor,
  textColor,
}: SelectableButtonProps) => {
  return (
    <SelectableButtonWrapper $isLoading={isLoading} data-testid="selectable-button">
      <SelectableButtonInput
        checked={isSelected}
        disabled={isLoading || disabled}
        onChange={onChange}
        $backgroundColor={backgroundColor}
        $textColor={textColor}
      />
      <SelectableButtonInner
        className={isLoading ? Classes.SKELETON : undefined}
        $backgroundColor={backgroundColor}
        $textColor={textColor}
      >
        {!!icon && <SelectableButtonIcon icon={icon} />}
        <SelectableButtonText>{name}</SelectableButtonText>
      </SelectableButtonInner>
    </SelectableButtonWrapper>
  );
};
