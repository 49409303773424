import { slice } from './slice';

export const { actions } = slice;

export const {
  openChangeFacilityDialog,
  closeChangeFacilityDialog,
  openDrawer,
  closeDrawer,
  openUniqueDrawer,
  deselectAlert,
  selectAlert,
  selectAlertEvent,
  deselectAlertEvent,
  setEventAudioVisualisation,
} = actions;
