import {
  InputGroupProps,
  PopoverPosition,
  PopoverInteractionKind,
  FormGroupProps,
  InputGroup,
  PopoverProps,
  Popover,
} from '@blueprintjs/core';
import { DatePickerProps } from '@blueprintjs/datetime';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useI18n } from 'core/hooks/useI18n';
import { DateTime } from 'luxon';
import { InputHTMLAttributes, PropsWithChildren, useCallback, useState } from 'react';
import { DateTimeSelector } from '../date-time-selector';
import { CalendarIconButton, StyledFormGroup } from './StyledComponents';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  label: string;
  value: DateTime;
  name: string;
  maxDate?: DateTime;
  dateOnly?: boolean;
  popoverProps?: PopoverProps;
  formGroupProps?: FormGroupProps;
  inputGroupProps?: InputGroupProps;
  dateTimeSelectorProps?: DatePickerProps;
  onChange: (value: DateTime) => void;
  disabled?: boolean;
}

export type DateTimeInputFieldProps = PropsWithChildren<
  IProps & Omit<InputGroupProps, 'id' | 'value' | 'onChange' | 'name'>
>;

export const DateTimeInputField = (props: DateTimeInputFieldProps) => {
  const {
    name,
    label,
    required,
    placeholder,
    maxDate,
    value,
    onChange,
    children,
    dateOnly,
    disabled,
    popoverProps = {},
    formGroupProps = {},
    inputGroupProps = {},
    dateTimeSelectorProps = {},
    ...restProps
  } = props;
  const { l10n } = useI18n('app/components', 'formikControls.datePicker');
  const [isOpen, setIsOpen] = useState(false);
  const valueMillis = value?.valueOf() ?? null;
  const displayValue = dateOnly
    ? value?.toLocaleString(DateTime.DATE_MED)
    : value?.toLocaleString(DateTime.DATETIME_MED) ?? '';
  const localTimeCallback = useLocalTimeCallback();
  const handleTimeChange = useCallback(
    (millis: number) => {
      onChange(localTimeCallback(millis));
      setIsOpen(false);
    },
    [onChange, localTimeCallback]
  );
  const handleOpen = useCallback(() => !disabled && setIsOpen(true), [disabled]);
  const handleInteraction = useCallback(
    (nextOpenState: boolean) => {
      !disabled && setIsOpen(nextOpenState);
    },
    [setIsOpen, disabled]
  );

  return (
    <Popover
      isOpen={isOpen}
      hasBackdrop={true}
      interactionKind={PopoverInteractionKind.CLICK}
      onInteraction={handleInteraction}
      canEscapeKeyClose={true}
      popoverClassName="inverse"
      usePortal={true}
      transitionDuration={0}
      position={PopoverPosition.BOTTOM_LEFT}
      minimal={true}
      modifiers={{ offset: { enabled: true, options: { offset: [0, 8] } } }}
      content={
        <DateTimeSelector
          maxDate={maxDate && localTimeCallback(maxDate).toJSDate()}
          initialValue={valueMillis}
          onValueChange={handleTimeChange}
          onCancel={() => setIsOpen(false)}
          dateOnly={dateOnly}
          {...dateTimeSelectorProps}
        />
      }
      {...restProps}
      {...popoverProps}
    >
      <StyledFormGroup $isRequired={required} {...formGroupProps}>
        {label && (
          // onClick is required for popover to work as intended
          // eslint-disable-next-line
          <label htmlFor={name} id={`${name}-label`} onClick={e => e.preventDefault()}>
            {label}
          </label>
        )}
        <InputGroup
          id={name}
          placeholder={placeholder}
          onFocus={handleOpen}
          rightElement={
            <CalendarIconButton title={`${l10n('title')} ${label}`} onClick={handleOpen} disabled={disabled}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </CalendarIconButton>
          }
          value={displayValue}
          readOnly={true}
          disabled={disabled}
          {...inputGroupProps}
        />
        {children}
      </StyledFormGroup>
    </Popover>
  );
};
