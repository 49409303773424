import { ModelRunData } from 'app/redux/modelling/types';
import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';

export type FetchModelDataSetBody = { modelIds: string[]; utcFromDate: string; utcToDate: string };

export const fetchModelDataSet = async (body: FetchModelDataSetBody) => {
  const { data, isSuccess }: HttpResponse<ModelRunData> = await httpClient.useInternalApi
    .asAuthorised()
    .post('gis-models/model-data', body);
  if (!isSuccess || !data) return Promise.reject();
  return data;
};
