import { CaseReducer } from '@reduxjs/toolkit';
import { fetchAlertPointReports } from '../fetchAlertPointReports';
import { ModellingState } from '../slice';

export const fetchAlertPointReportsPending: CaseReducer<
  ModellingState,
  ReturnType<typeof fetchAlertPointReports.pending>
> = (state, { meta: { requestId } }) => {
  return {
    ...state,
    data: {
      ...state.data,
      alertPointReports: {
        status: 'Loading',
        requestId,
      },
    },
  };
};
