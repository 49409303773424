import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { useHasAccess } from 'app/hooks/use-has-access';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { formatAlertHistory } from 'app/views/alerts/pages/alert-history/use-alert-history/formatAlertHistory';
import { useAlertResponseCategories } from 'app/views/configuration/pages/alert/alert-responses/alert-response-form/useAlertResponseCategories';
import { useMemo } from 'react';
import { fetchAlertHistory } from '../../pages/alert-history/use-alert-history/fetchAlertHistory';
import { useAlertConfigurations } from 'app/views/alerts/pages/alert-history/alert-history-filters/useAlertConfigurations';

export const useAlertsDrawerData = () => {
  const localTime = useLocalTimeCallback();
  const hasAlertsAccess = useHasAccess()(['app.alerts']);
  const { data: alertResponseCategories } = useAlertResponseCategories();
  const { data: alertConfigurations } = useAlertConfigurations();
  const webAlertConfigurationIds = useMemo(
    () =>
      alertConfigurations?.flatMap(({ id, isWebAlert }) => {
        if (!isWebAlert) return [];
        return id;
      }) ?? [],
    [alertConfigurations]
  );
  const hasWebAlerts = webAlertConfigurationIds.length > 0;
  const {
    dataUpdatedAt,
    data: alertHistory,
    isSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.alertDrawerData, webAlertConfigurationIds],
    queryFn: async () => {
      return fetchAlertHistory({
        paging: {
          page: 1,
          pageSize: 200,
        },
        filter: {
          utcStart: localTime().minus({ hours: 24 }).toUTC().toISO(),
          excludeAcknowledged: true,
          alertScriptConfigurationIds: webAlertConfigurationIds,
        },
      });
    },
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
    staleTime: 0,
    refetchOnMount: false,
    enabled: hasAlertsAccess && hasWebAlerts,
  });

  const data = useMemo(
    () =>
      formatAlertHistory({
        alertHistory: alertHistory ?? {
          page: 1,
          recordCount: 0,
          results: [],
        },
        alertResponseCategories,
      }),
    [alertHistory, alertResponseCategories]
  );

  const lastUpdated = useMemo(() => (dataUpdatedAt ? localTime(dataUpdatedAt) : null), [dataUpdatedAt, localTime]);
  return {
    data: data.results.sort((a, b) => b.time.localeCompare(a.time)),
    lastUpdated,
    isSuccess,
  };
};
