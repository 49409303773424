import { NoiseEvent } from 'app/business-logic/services/noise-service';
import { NoiseMeasurementType } from 'app/views/components/real-time-noise-connection/RealTimeNoiseConnection.types';
import Guid from 'core/types/Guid';
import { DateTime } from 'luxon';

import { useAlertById } from '../../../hooks';
import { useAlertConfiguration } from '../../../hooks/use-alert-configuration';
import { useEventsByAlertId } from '../../events-listing/useEventsByAlertId';
import { useTraceVariableIds } from './useTraceVariableIds';

type ConfigData = {
  traceVariableIds?: Guid[];
  omnisLocationId?: number | null;
  omnisDataType?: NoiseMeasurementType | null;
  utcEndDate?: string;
  utcStartDate?: string;
  events?: NoiseEvent[] | null;
  isLoading: boolean;
  displayUnit?: string;
  thresholds?: {
    valueInDisplayUnit: number;
    hexColor: string;
  }[];
};

export const useConfigData = (alertId: Guid): ConfigData => {
  const { data: alert, isFetching: isAlertLoading } = useAlertById(alertId);
  const { alertScriptConfigurationId } = alert ?? {};
  const alertEventId = alert?.id;
  const { data: config, isFetching: isAlertConfigLoading } = useAlertConfiguration(alertScriptConfigurationId);
  const { data: traceVariableIds, isFetching: isTraceVariablesLoading } =
    useTraceVariableIds(alertScriptConfigurationId);
  const { data: events, isFetching: isEventsLoading } = useEventsByAlertId(alertEventId);
  const {
    traceWindowLeadInSeconds,
    traceWindowLeadOutSeconds,
    omnisLocationId,
    omnisDataType,
    displayUnit,
    thresholds,
  } = config ?? {};
  const dateRange = getEventsChartDateRage({
    events,
    alert,
    traceWindowLeadInSeconds,
    traceWindowLeadOutSeconds,
  });
  const { utcEndDate, utcStartDate } = dateRange ?? {};
  return {
    traceVariableIds,
    omnisLocationId,
    omnisDataType,
    utcEndDate: utcEndDate ?? undefined,
    utcStartDate: utcStartDate ?? undefined,
    events,
    isLoading: isAlertLoading || isAlertConfigLoading || isTraceVariablesLoading || isEventsLoading,
    displayUnit,
    thresholds,
  };
};

function getEventsChartDateRage({
  events,
  alert,
  traceWindowLeadInSeconds,
  traceWindowLeadOutSeconds,
}: {
  events?: NoiseEvent[] | null;
  alert?: ReturnType<typeof useAlertById>['data'];
  traceWindowLeadInSeconds?: number | null;
  traceWindowLeadOutSeconds?: number | null;
}) {
  if (!alert) return;

  if (typeof traceWindowLeadInSeconds !== 'number' || typeof traceWindowLeadOutSeconds !== 'number') return;

  const { utcTriggeredTime } = alert;

  const earliestAlertPeriod = DateTime.fromISO(utcTriggeredTime).toUTC().minus({ hours: 12 }).toMillis();
  const latestAlertPeriod = DateTime.fromISO(utcTriggeredTime).toUTC().plus({ hours: 12 }).toMillis();

  const eventTimes = events
    ?.flatMap(event => [DateTime.fromISO(event.startTime).toUTC(), DateTime.fromISO(event.endTime).toUTC()])
    .sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

  const earliestEventTime = eventTimes?.at(0);
  const latestEventTime = eventTimes?.at(-1);

  // Events occurring 12 hours before or after the alert triggered time are invalid
  const eventsAreInvalid =
    events?.some(event => {
      const eventStartTime = DateTime.fromISO(event.startTime).toUTC().toMillis();
      const eventEndTime = DateTime.fromISO(event.endTime).toUTC().toMillis();
      return eventStartTime > latestAlertPeriod || eventEndTime < earliestAlertPeriod;
    }) ||
    !earliestEventTime ||
    !latestEventTime;

  // If the events are invalid, the chart should display the period from (alert triggered time - lead in seconds) to (alert triggered time + lead out seconds).
  if (eventsAreInvalid) {
    const utcStartDate = DateTime.fromISO(utcTriggeredTime)
      .toUTC()
      .minus({ seconds: traceWindowLeadInSeconds })
      .toISO();
    const utcEndDate = DateTime.fromISO(utcTriggeredTime).toUTC().plus({ seconds: traceWindowLeadOutSeconds }).toISO();
    return {
      utcStartDate,
      utcEndDate,
    };
  } else {
    // If the events are valid the chart should display the period from (earliest event time - lead in seconds) to (latest event time + lead out seconds).
    const utcStartDate = earliestEventTime.minus({ seconds: traceWindowLeadInSeconds }).toISO();
    const utcEndDate = latestEventTime.plus({ seconds: traceWindowLeadOutSeconds }).toISO();

    return {
      utcStartDate,
      utcEndDate,
    };
  }
}
