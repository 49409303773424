import { CaseReducer } from '@reduxjs/toolkit';
import { fetchModelData } from '../fetchModelData';
import { ModellingState } from '../slice';

export const fetchModelDataRejected: CaseReducer<ModellingState, ReturnType<typeof fetchModelData.rejected>> = (
  state,
  { meta }
) => {
  const {
    arg: { modelIds },
    requestId,
  } = meta;
  return {
    ...state,
    data: {
      ...state.data,
      models: state.data.models.map(model => {
        if (!modelIds.includes(model.modelId)) return model;
        return {
          ...model,
          request: { status: 'Failure', requestId },
        };
      }),
    },
  };
};
