import { Position } from 'geojson';
import { IConcentrationRegion } from 'app/business-logic/domain-models/Blasting';

export const validatePolygon = (coordinates: Position[]) => {
  if (coordinates.length <= 3) return [];
  const firstPoint = coordinates[0];
  const lastPoint = coordinates[coordinates.length - 1];
  if (!firstPoint || !lastPoint) return [];
  const [firstLongitude, firstLatitude, firstElevation] = firstPoint;
  const [finalLongitude, finalLatitude, finalElevation] = lastPoint;
  // The final coordinate for a valid polygon in GeoJSON must be the same as the first coordinate
  const isValidPolygon =
    firstLongitude === finalLongitude && firstLatitude === finalLatitude && firstElevation === finalElevation;
  if (!isValidPolygon) return [...coordinates, firstPoint];
  return coordinates;
};

export const getValidatedRegionCoordinates = (regions: IConcentrationRegion[]) => {
  return regions.flatMap(({ gisPoints }) => {
    const points: Position[] = gisPoints.map(({ longitude, latitude, elevation }) => [longitude, latitude, elevation]);
    const validatedPolygon = validatePolygon(points);
    if (validatedPolygon.length === 0) return [];
    return [[validatedPolygon]];
  });
};

export const isValidPolygon = (coordinates: Position[]) => {
  const validatedPolygon = validatePolygon(coordinates);
  return validatedPolygon.length > 0;
};
