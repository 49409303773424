import { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Routing, useRouting } from 'core/routing/Routing';
import { AccessibleFacility } from 'app/redux/profile/user/slice';
import { LogManager } from 'core/logging/LogManager';
import { fetchAccessControls, useClearCache } from 'app/business-logic/services/security-service';
import { useGetFallbackRoute } from 'app/views/useGetFallbackRoute';
import { ApolloProvider, createProviderLink } from 'core/net/ApolloProvider';

const logger = LogManager.getLogger('useGoToNewFacility');

export const useGoToNewFacility = () => {
  const navigate = useNavigate();
  const getNewPath = useGetNewPath();
  const clearCache = useClearCache();
  return useCallback(
    async (facility: AccessibleFacility) => {
      const { companyAlias, facilityAlias } = facility;
      const newPath = await getNewPath(facility);
      logger.debug('[Omnis] Clearing all caches');
      clearCache();
      logger.debug('[Apollo] Setting new link');
      ApolloProvider.global().setLink(createProviderLink({ companyAlias, facilityAlias }));
      logger.debug(`[Omnis] Going to new facility: ${newPath}`);
      navigate(newPath);
    },
    [clearCache, getNewPath, navigate]
  );
};

function useGetNewPath() {
  const { generatePath } = useRouting();
  const getFallbackRoute = useGetFallbackRoute();

  const isMonitoring = useMatch({ path: Routing.routes.monitoring.root, end: false }) !== null;
  const isModelling = useMatch({ path: Routing.routes.modelling.root, end: false }) !== null;
  const isTrajectories = useMatch({ path: Routing.routes.trajectories.root, end: false }) !== null;
  const isBlasting = useMatch({ path: Routing.routes.blasting.root, end: false }) !== null;
  const isIncidentIntelligence = useMatch({ path: Routing.routes.incidentIntelligence.root, end: false }) !== null;
  const isAlerts = useMatch({ path: Routing.routes.alerts.root, end: false }) !== null;
  const isSettings = useMatch({ path: Routing.routes.configuration.root, end: false }) !== null;

  return useCallback(
    async (facility: AccessibleFacility) => {
      const { id, companyId, companyAlias, facilityAlias } = facility;
      const accessControls = await fetchAccessControls(companyId, id);
      if (!accessControls) throw new Error(`Failed to fetch access controls for: ${companyAlias}/${facilityAlias}`);
      if (isMonitoring && accessControls['app.monitoring'])
        return generatePath(Routing.routes.monitoring.realTime(), { companyAlias, facilityAlias });
      if (isModelling && accessControls['app.modelling'])
        return generatePath(Routing.routes.modelling.root, { companyAlias, facilityAlias });
      if (isTrajectories && accessControls['app.modelling.trajectory'])
        return generatePath(Routing.routes.trajectories.root, { companyAlias, facilityAlias });
      if (isBlasting && accessControls['app.blasting'])
        return generatePath(Routing.routes.blasting.root, { companyAlias, facilityAlias });
      if (isIncidentIntelligence && accessControls['app.incident-intelligence'])
        return generatePath(Routing.routes.incidentIntelligence.root, { companyAlias, facilityAlias });
      if (isAlerts && accessControls['app.alerts'])
        return generatePath(Routing.routes.alerts.root, { companyAlias, facilityAlias });
      if (isSettings && accessControls['app.config'])
        return generatePath(Routing.routes.configuration.root, { companyAlias, facilityAlias });
      return getFallbackRoute({ facility, accessControls });
    },
    [
      generatePath,
      getFallbackRoute,
      isAlerts,
      isBlasting,
      isIncidentIntelligence,
      isModelling,
      isMonitoring,
      isSettings,
      isTrajectories,
    ]
  );
}
