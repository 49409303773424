import { CaseReducer } from '@reduxjs/toolkit';
import { fetchAlertPointData } from '../fetchAlertPointData';
import { ModellingState } from '../slice';

export const fetchAlertPointDataFulfilled: CaseReducer<
  ModellingState,
  ReturnType<typeof fetchAlertPointData.fulfilled>
> = (state, { meta: { requestId }, payload }) => {
  if (Object.keys(payload).length === 0) {
    return {
      ...state,
      data: {
        ...state.data,
        alertPoints: state.data.alertPoints.filter(point => {
          return point.request.requestId !== requestId;
        }),
      },
    };
  } else {
    return {
      ...state,
      data: {
        ...state.data,
        alertPoints: state.data.alertPoints.map(point => {
          if (point.request.requestId !== requestId) return point;
          return {
            ...point,
            request: {
              status: 'Success',
              requestId,
              data: payload,
            },
          };
        }),
      },
    };
  }
};
