import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { slice, UiState } from './slice';

// ****************************************************************************************

const currentVersion = 4; // increment this number every time the state contract changes

// ****************************************************************************************

const whitelist: (keyof UiState)[] = ['openingDrawers', 'eventAudioVisualisation'];

const persistenceConfig: PersistConfig<UiState> = {
  key: 'ui',
  storage,
  version: currentVersion,
  whitelist,
};

export const persistedReducer = persistReducer<UiState>(persistenceConfig, slice.reducer);
