import { CaseReducer } from '@reduxjs/toolkit';
import { fetchAlertPointData } from '../fetchAlertPointData';
import { ModellingState } from '../slice';

export const fetchAlertPointDataRejected: CaseReducer<ModellingState, ReturnType<typeof fetchAlertPointData.rejected>> =
  (state, { meta: { requestId } }) => {
    return {
      ...state,
      data: {
        ...state.data,
        alertPoints: state.data.alertPoints.map(point => {
          if (point.request.requestId !== requestId) return point;
          return {
            ...point,
            request: { status: 'Failure', requestId },
          };
        }),
      },
    };
  };
