import { Icon } from 'app/components/icon';
import { DownloadAudioButton, ButtonText, DownloadAudioButtonInner } from './DownloadAudio.styles';
import { useHandleAudioDownload } from './useHandleAudioDownload';
import { useI18n } from 'core/hooks/useI18n';

export type DownloadAudioProps = {
  data: Blob;
  noiseEventId: number;
  startTime: string;
};

export const DownloadAudio = ({ data, noiseEventId, startTime }: DownloadAudioProps) => {
  const { l10n } = useI18n('app/views/alerts/components/event-noise-playback', 'audio');
  const handleAudioDownload = useHandleAudioDownload({ data, noiseEventId, startTime });
  return (
    <DownloadAudioButton $variant="text" onClick={handleAudioDownload()}>
      <DownloadAudioButtonInner>
        <Icon.Download width="16px" height="16px" /> <ButtonText>{l10n('download')}</ButtonText>
      </DownloadAudioButtonInner>
    </DownloadAudioButton>
  );
};
