import { CaseReducer } from '@reduxjs/toolkit';
import { fetchModelData } from '../fetchModelData';
import { ModellingState } from '../slice';

export const fetchModelDataFulfilled: CaseReducer<ModellingState, ReturnType<typeof fetchModelData.fulfilled>> = (
  state,
  { payload: { availableGisModels }, meta: { requestId } }
) => {
  return {
    ...state,
    data: {
      ...state.data,
      models: state.data.models.map(model => {
        if (model.request.status !== 'Loading') return model;
        if (model.request.requestId !== requestId) return model;
        const availableModel = availableGisModels.find(({ modelId }) => modelId === model.modelId);
        if (!availableModel) return model;
        return {
          ...model,
          request: { status: 'Success', requestId, data: availableModel },
        };
      }),
    },
  };
};
