const alertRoot = '/alert';

const alertResponsesRoot = (root: string) => `${root}${alertRoot}/alert-responses`;

const alertResponsesCreate = (root: string) => () => {
  return `${alertResponsesRoot(root)}/add`;
};

const alertResponsesEdit = (root: string) => () => {
  return `${alertResponsesRoot(root)}/edit/:id`;
};

export const configurationAlertRoutes = (root: string) => ({
  root: `${root}${alertRoot}`,
  alertResponses: {
    root: alertResponsesRoot(root),
    create: alertResponsesCreate(root),
    edit: alertResponsesEdit(root),
  },
});
