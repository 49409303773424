import { styled, css } from 'styled-components';
import { ReactComponent as NavIcon } from 'app/components/icon/assets/bars.svg';
import { CircularButton } from 'app/components/buttons/circular-button';

export const MOBILE_NAVIGATION_BAR_HEIGHT = 50;

export const MobileNavWrapper = styled.div`
  height: ${MOBILE_NAVIGATION_BAR_HEIGHT}px;
  position: relative;
  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        display: none;
      `
    )}
`;

export const MobileNavHeader = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.headerBar};
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  height: ${MOBILE_NAVIGATION_BAR_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
`;

export const MobileNav = styled.nav<{ $isOpen: boolean }>`
  transform: translateY(-100%);
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(100vh - ${MOBILE_NAVIGATION_BAR_HEIGHT}px); // Fallback for safety, in case --doc-height goes missing
  height: calc(var(--doc-height) - ${MOBILE_NAVIGATION_BAR_HEIGHT}px);
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndex.headerBar - 1};
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  transition:
    opacity 0.2s ease-out,
    transform 0.2s ease-out;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`;

export const MobileNavMenuButton = styled.button``;

export const MenuIcon = styled(NavIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

export const MobileNavHeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MobileNavHeaderLeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MobileNavHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MobileNavHeaderRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing()}px;
  ${CircularButton} {
    background-color: transparent;
    border: 0;
  }
`;
