import { KmlOverlay } from 'app/redux/map/types';
import { EMISSION_SOURCES_FILENAME } from 'app/redux/modelling/fetchEmissionSources';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import flatten from 'geojson-flatten';
import { kml as kmlToGeoJson } from '@tmcw/togeojson';
import { validatePolygon } from 'app/business-logic/helpers/validatePolygon';
import { parseKmlFiles } from './parseKmlFiles';
import { IRawKmlFile } from './types';

export const createKmlGeoJson = (kml: IRawKmlFile[], includeFiles?: string[]) => {
  const isEmissionSource = includeFiles?.includes(EMISSION_SOURCES_FILENAME) ?? false;
  const parsedKmls = parseKmlFiles(kml);
  const kmlFilesAsGeoJson: KmlOverlay[] = parsedKmls.map(({ data }) => {
    const { features, ...rest } = kmlToGeoJson(data);
    // Accounting for MultiPoint, MultiPolygon, MultiLineString and GeometryCollection
    const flattedFeatures = features.flatMap(feature => {
      if (!feature.geometry) return [];
      const flattenedFeature = flatten(feature);
      if (Array.isArray(flattenedFeature)) {
        // Types are wrong, can be an array of values - https://www.npmjs.com/package/geojson-flatten
        return (flattenedFeature as Feature<Geometry, GeoJsonProperties>[]).map(f => {
          const validatedGeometry = validateGeometry(f.geometry);
          return {
            ...f,
            geometry: validatedGeometry,
            properties: { ...f.properties, id: f.properties?.name, isEmissionSource },
          };
        });
      }
      const validatedGeometry = validateGeometry(flattenedFeature.geometry);
      return {
        ...flattenedFeature,
        geometry: validatedGeometry,
        properties: { ...flattenedFeature.properties, id: flattenedFeature.properties?.name, isEmissionSource },
      };
    });
    return {
      ...rest,
      features: flattedFeatures,
    };
  });
  return kmlFilesAsGeoJson;
};

function validateGeometry(geometry: Feature<Geometry, GeoJsonProperties>['geometry']) {
  if (geometry.type !== 'Polygon') return geometry;
  // Polygons in GeoJSON are only valid if their last coordinate is the same as their first coordinate.
  // Add first coordinate as last coordinate if polygon is invalid.
  const coords = geometry.coordinates[0];
  if (!coords) throw new Error('Invalid coordinates');
  const validatedCoords = validatePolygon(coords);
  return { ...geometry, coordinates: [validatedCoords] };
}
