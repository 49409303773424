const DATA_TRACKING_KEY_VALUE_PAIRS = {
  // monitoring map controls
  'monitoring-alerts-button': 'monitoring-alerts-button',
  'monitoring-back-to-real-time-button': 'monitoring-back-to-real-time-button',
  'monitoring-change-facility-button': 'monitoring-change-facility-button',
  'monitoring-map': 'monitoring-map',
  'monitoring-map-grid-toggle': 'monitoring-map-grid-toggle',
  'monitoring-map-reset-button': 'monitoring-map-reset-button',
  'monitoring-map-time-selector': 'monitoring-map-time-selector',
  'monitoring-playback-timeline': 'monitoring-playback-timeline',
  'monitoring-sensor-group-item': 'monitoring-sensor-group-item',
  'monitoring-sensor-group-item-custom': 'monitoring-sensor-group-item-custom',
  'monitoring-sensor-group-selector': 'monitoring-sensor-group-selector',
  'monitoring-weather-station-item': 'monitoring-weather-station-item',
  'monitoring-weather-stations-selector': 'monitoring-weather-stations-selector',

  // general map controls
  'zoom-in-button': 'zoom-in-button',
  'zoom-out-button': 'zoom-out-button',

  // monitoring map layers controls
  'map-layers-activity-location-toggle': 'map-layers-activity-location-toggle',
  'map-layers-all-monitors-toggle': 'map-layers-all-monitors-toggle',
  'map-layers-button': 'map-layers-button',
  'map-layers-custom-image-overlays-toggle': 'map-layers-custom-image-overlays-toggle',
  'map-layers-diagnostics-influence-arcs-toggle': 'map-layers-diagnostics-influence-arcs-toggle',
  'map-layers-diagnostics-turn-off-toggle': 'map-layers-diagnostics-turn-off-toggle',
  'map-layers-exceeding-monitors-toggle': 'map-layers-exceeding-monitors-toggle',
  'map-layers-monitor-names-toggle': 'map-layers-monitor-names-toggle',
  'map-layers-other-monitor-position-toggle': 'map-layers-other-monitor-position-toggle',
  'map-layers-source-area-toggle': 'map-layers-source-area-toggle',

  // shared map layers controls
  'map-layers-domain-boundaries-toggle': 'map-layers-domain-boundaries-toggle',
  'map-layers-KML-overlays-toggle': 'map-layers-KML-overlays-toggle',
  'map-layers-roadmap-button': 'map-layers-roadmap-button',
  'map-layers-satellite-button': 'map-layers-satellite-button',
  'map-layers-satellite-greyscale-button': 'map-layers-satellite-greyscale-button',

  // monitoring map
  'monitoring-monitor-marker': 'monitoring-monitor-marker',
  'monitoring-weather-marker': 'monitoring-weather-marker',

  // grid view
  'grid-add-card-button': 'grid-add-card-button',
  'grid-chart-card': 'grid-chart-card',
  'grid-chart-card-drag-handle': 'grid-chart-card-drag-handle',
  'grid-sort-by-alphabetical': 'grid-sort-by-alphabetical',
  'grid-sort-by-button': 'grid-sort-by-button',
  'grid-sort-by-highest-exceedance': 'grid-sort-by-highest-exceedance',
  'grid-sort-by-highest-value': 'grid-sort-by-highest-value',

  // analyse drawer
  'analyse-drawer-chart': 'analyse-drawer-chart',
  'analyse-drawer-close-button': 'analyse-drawer-close-button',
  'analyse-drawer-collapse-button': 'analyse-drawer-collapse-button',
  'analyse-drawer-expand-button': 'analyse-drawer-expand-button',
  'analyse-drawer-date-range-last-6-hours': 'analyse-drawer-date-range-last-6-hours',
  'analyse-drawer-date-range-last-7-days': 'analyse-drawer-date-range-last-7-days',
  'analyse-drawer-date-range-last-24-hours': 'analyse-drawer-date-range-last-24-hours',
  'analyse-drawer-date-range-last-hour': 'analyse-drawer-date-range-last-hour',
  'analyse-drawer-date-range-selector-button': 'analyse-drawer-date-range-selector-button',
  'analyse-drawer-process-variable': 'analyse-drawer-process-variable',

  // main navigation bar top items
  'main-nav-bar-logo': 'main-nav-bar-logo',
  'main-nav-bar-overview-link': 'main-nav-bar-overview-link',
  'main-nav-bar-monitoring-link': 'main-nav-bar-monitoring-link',
  'main-nav-bar-modelling-link': 'main-nav-bar-modelling-link',
  'main-nav-bar-alerts-link': 'main-nav-bar-alerts-link',
  'main-nav-bar-trajectories-link': 'main-nav-bar-trajectories-link',
  'main-nav-bar-blasting-link': 'main-nav-bar-blasting-link',
  'main-nav-bar-incident-intelligence-link': 'main-nav-bar-incident-intelligence-link',
  'main-nav-bar-annotations-link': 'main-nav-bar-annotations-link',
  'main-nav-bar-reports-link': 'main-nav-bar-reports-link',

  // main navigation bar bottom items
  'main-nav-bar-site-config-link': 'main-nav-bar-site-config-link',
  'main-nav-bar-user-settings-link': 'main-nav-bar-user-settings-link',
  'main-nav-bar-support-link': 'main-nav-bar-support-link',
  'main-nav-bar-logout-link': 'main-nav-bar-logout-link',

  // model setup
  'modelling-setup-model-intervals': 'modelling-setup-model-intervals',
  'modelling-setup-date-time': 'modelling-setup-date-time',
  'modelling-setup-model-from': 'modelling-setup-model-from',
  'modelling-setup-model-to': 'modelling-setup-model-to',
  'modelling-setup-auto-update': 'modelling-setup-auto-update',

  // models list
  'modelling-list-model-toggle': 'modelling-list-model-toggle',

  // emission sources
  'modelling-emission-sources-toggle': 'modelling-emission-sources-toggle',

  // modelling time slider
  'modelling-time-slider': 'modelling-time-slider',
  'modelling-time-slider-play': 'modelling-time-slider-play',
  'modelling-time-slider-pause': 'modelling-time-slider-pause',

  // alert history - date range filter
  'alert-history-filter-date-range-apply': 'alert-history-filter-date-range-apply',
  'alert-history-filter-date-range-clear': 'alert-history-filter-date-range-clear',

  // alert history - type filter
  'alert-history-filter-type-option': 'alert-history-filter-type-option',
  'alert-history-filter-type-clear': 'alert-history-filter-type-clear',

  // alert history - alert filter
  'alert-history-filter-alert-option': 'alert-history-filter-alert-option',
  'alert-history-filter-alert-clear': 'alert-history-filter-alert-clear',

  // alert history - monitor filter
  'alert-history-filter-monitor-option': 'alert-history-filter-monitor-option',
  'alert-history-filter-monitor-clear': 'alert-history-filter-monitor-clear',

  // alert history - response filter
  'alert-history-filter-response-option': 'alert-history-filter-response-option',
  'alert-history-filter-response-clear': 'alert-history-filter-response-clear',

  // alert history - state filter
  'alert-history-filter-response-state-option': 'alert-history-filter-response-state-option',
  'alert-history-filter-response-state-clear': 'alert-history-filter-response-state-clear',

  // alert history - action by filter
  'alert-history-filter-action-by-option': 'alert-history-filter-action-by-option',
  'alert-history-filter-action-by-clear': 'alert-history-filter-action-by-clear',

  // alert history - alert link
  'alert-history-alert-link': 'alert-history-alert-link',

  // pagination
  'pagination-previous': 'pagination-previous',
  'pagination-next': 'pagination-next',
  'pagination-number': 'pagination-number',

  // Historical reports
  'historical-reports-download-link': 'historical-reports-download-link',
  'historical-reports-filter-option-report-definition': 'historical-reports-filter-option-report-definition',
  'historical-reports-filter-option-report-year': 'historical-reports-filter-option-report-year',
} as const;

const DATA_TRACKING_KEY_VALUES = Object.values(DATA_TRACKING_KEY_VALUE_PAIRS);

export type DataTrackingKeys = (typeof DATA_TRACKING_KEY_VALUES)[number];

export const DATA_TRACKING_KEY: { [Key in DataTrackingKeys]: Key } = {
  ...DATA_TRACKING_KEY_VALUE_PAIRS,
};
