import { ReactComponent as Icon } from 'app/visuals/images/icons/new-window.svg';
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

export const StyledViewAlertHeader = styled.header`
  && {
    ${({ theme }) =>
      theme.breakpoints.down(
        'sm',
        css`
          padding: ${({ theme }) => theme.spacing(2)}px;
        `
      )}
  }
`;

export const ViewAlertHeaderItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-width: 100%;

  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
      `
    )}
`;

export const ViewAlertHeaderItem = styled.li`
  & + & {
    margin-top: ${({ theme }) => theme.spacing(1.5)}px;
    ${({ theme }) =>
      theme.breakpoints.up(
        'sm',
        css`
          margin-top: 0;
          margin-left: ${({ theme }) => theme.spacing(3)}px;
        `
      )}
  }
`;

export const ViewAlertHeaderTruncatedItem = styled(ViewAlertHeaderItem)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ViewAlertHeaderItemTitle = styled.h6`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.mono.text02};
  margin: 0;
`;

export const ViewAlertHeaderItemDescription = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.text01};
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing(0.25)}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        margin-top: ${({ theme }) => theme.spacing(0.5)}px;
      `
    )}
`;
export const ViewAlertHeaderItemThreshold = styled.span`
  color: ${({ theme }) => theme.colors.mono.text02};
`;

export const ViewAlertHeaderItemValue = styled.span`
  font-weight: ${({ theme }) => theme.font.default.weight.bold};
`;

export const OpenAlert = styled(Link)`
  && {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.mono.text02};
    flex-shrink: 0;
    margin-left: auto;

    &:hover {
      color: ${({ theme }) => theme.colors.mono.text01};
    }
  }
`;

export const NewWindowIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-left: ${({ theme }) => theme.spacing()}px;
  fill: currentColor;
`;
