import { NotFound } from 'app/components/error/NotFound';
import { UnexpectedError } from 'app/components/error/UnexpectedError';
import { Throbber } from 'app/components/throbber';
import { useJobStatusNotifications } from 'app/hooks/use-job-status';
import { useAppSelector } from 'app/redux/store';
import { useI18n } from 'core/hooks/useI18n';
import { Routing } from 'core/routing/Routing';
import { COMPANY_FACILITY } from 'core/routing/Routing.constants';
import { lazy, Suspense } from 'react';
import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';
import { LayoutWrapper } from './LayoutWrapper';
import { useGetFallbackRoute } from './useGetFallbackRoute';

const Alerts = lazy(() => import('app/views/alerts'));
const Annotations = lazy(() => import('app/views/annotations'));
const AnnotationForm = lazy(() => import('app/views/annotations/components/AnnotationForm'));
const Blasting = lazy(() => import('app/views/blasting'));
const CreateScenario = lazy(() => import('app/views/blasting/pages/create-scenario'));
const ScenariosListing = lazy(() => import('app/views/blasting/pages/scenarios-listing'));
const ViewScenario = lazy(() => import('app/views/blasting/pages/view-scenario'));
const Configuration = lazy(() => import('app/views/configuration'));
const Aermet = lazy(() => import('app/views/configuration/pages/aermet'));
const EmissionData = lazy(() => import('app/views/configuration/pages/emission-data'));
const MeasurementGroups = lazy(() => import('app/views/configuration/pages/measurement-groups'));
const MeasurementGroup = lazy(
  () => import('app/views/configuration/pages/measurement-groups/components/MeasurementGroup')
);
const EditModel = lazy(() => import('app/views/configuration/pages/models/components/EditModel'));
const Models = lazy(() => import('app/views/configuration/pages/models'));
const Monitors = lazy(() => import('app/views/configuration/pages/monitors'));
const DataImport = lazy(() => import('app/views/configuration/pages/monitors/data-import'));
const CredentialForm = lazy(() => import('app/views/configuration/pages/monitors/data-import/CredentialForm'));
const MonitorDetails = lazy(() => import('app/views/configuration/pages/monitors/details'));
const MapConfiguration = lazy(() => import('app/views/configuration/pages/monitors/map-configuration'));
const MeasurementTypes = lazy(() => import('app/views/configuration/pages/monitors/measurement-types'));
const MeasurementTypeForm = lazy(
  () => import('app/views/configuration/pages/monitors/measurement-types/MeasurementTypeForm')
);
const Settings = lazy(() => import('app/views/configuration/pages/settings'));
const Grid = lazy(() => import('app/views/grid'));
const IncidentIntelligence = lazy(() => import('app/views/incident-intelligence'));
const IncidentIntelligenceTickets = lazy(() => import('app/views/incident-intelligence/pages/tickets'));
const Modelling = lazy(() => import('app/views/modelling'));
const Monitoring = lazy(() => import('app/views/monitoring'));
const Profile = lazy(() => import('app/views/profile'));
const About = lazy(() => import('app/views/profile/pages/about'));
const ChangePassword = lazy(() => import('app/views/profile/pages/change-password'));
const EditProfile = lazy(() => import('app/views/profile/pages/edit-profile'));
const LanguageSettings = lazy(() => import('app/views/profile/pages/language-settings'));
const Trajectories = lazy(() => import('app/views/trajectories'));
const CreateNewTrajectory = lazy(() => import('app/views/trajectories/pages/create-new-trajectory'));
const TrajectoriesListing = lazy(() => import('app/views/trajectories/pages/trajectories-listing'));
const ViewTrajectory = lazy(() => import('app/views/trajectories/pages/view-trajectory'));
const AlertHistory = lazy(() => import('./alerts/pages/alert-history'));
const ViewAlert = lazy(() => import('./alerts/pages/view-alert'));
const EventHistory = lazy(() => import('./alerts/pages/event-history'));
const VibrationEvents = lazy(() => import('./alerts/pages/vibration-events'));
const NoiseClassifications = lazy(() => import('./configuration/pages/noise/noise-classifications'));
const EditNoiseClassification = lazy(
  () => import('./configuration/pages/noise/noise-classifications/edit-noise-classification')
);
const AddNoiseClassification = lazy(
  () => import('./configuration/pages/noise/noise-classifications/add-noise-classification')
);
const AlertResponses = lazy(() => import('./configuration/pages/alert/alert-responses'));
const AddAlertResponseClassification = lazy(
  () => import('./configuration/pages/alert/alert-responses/add-alert-response-classification')
);
const EditAlertResponseClassification = lazy(
  () => import('./configuration/pages/alert/alert-responses/edit-alert-response-classification')
);

const Reports = lazy(() => import('app/views/reports'));
const ReportsListing = lazy(() => import('app/views/reports/pages/reports-page'));
const HistoricalReports = lazy(() => import('app/views/reports/pages/historical-reports'));
const LegacyReports = lazy(() => import('app/views/reports/pages/legacy-reports'));

const FALLBACK_ROUTES = [COMPANY_FACILITY, '/'];

export const Layout = () => {
  const route = useRouteObject();
  return <RouterProvider router={createBrowserRouter(route)} />;
};

export const useRouteObject = () => {
  const { l10n } = useI18n('app');
  const {
    facility,
    user: { accessControls },
  } = useAppSelector(state => state.profile);
  const fallbackRoute = useGetFallbackRoute()({ facility, accessControls });

  useJobStatusNotifications();

  const routes = (
    <>
      <Route path="/" element={<LayoutWrapper />} ErrorBoundary={RouteErrorBoundary}>
        <Route
          path={Routing.routes.monitoring.realTime()}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.monitoring')}</Throbber>}>
              <Monitoring />
            </Suspense>
          }
        />
        <Route
          path={Routing.routes.monitoring.analyse()}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.monitoring')}</Throbber>}>
              <Monitoring />
            </Suspense>
          }
        />
        <Route
          path={Routing.routes.modelling.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.modelling')}</Throbber>}>
              <Modelling />
            </Suspense>
          }
        />
        <Route
          path={Routing.routes.incidentIntelligence.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.incidentIntelligence')}</Throbber>}>
              <IncidentIntelligence />
            </Suspense>
          }
        />
        <Route
          path={Routing.routes.grid.realTime()}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.grid')}</Throbber>}>
              <Grid />
            </Suspense>
          }
        />
        <Route
          path={Routing.routes.grid.analyse()}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.grid')}</Throbber>}>
              <Grid />
            </Suspense>
          }
        />
        <Route
          path={Routing.routes.annotations.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.annotations')}</Throbber>}>
              <Annotations />
            </Suspense>
          }
        >
          {[Routing.routes.annotations.edit(), Routing.routes.annotations.create()].map(path => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.annotations')}</Throbber>}>
                  <AnnotationForm />
                </Suspense>
              }
            />
          ))}
        </Route>

        <Route
          path={Routing.routes.profile.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.userSettings')}</Throbber>}>
              <Profile />
            </Suspense>
          }
        >
          <Route
            path={Routing.routes.profile.editProfile()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.userSettings')}</Throbber>}>
                <EditProfile />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.profile.changePassword()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.userSettings')}</Throbber>}>
                <ChangePassword />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.profile.languageSettings()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.userSettings')}</Throbber>}>
                <LanguageSettings />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.profile.about()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.userSettings')}</Throbber>}>
                <About />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={Routing.routes.reports.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.reports')}</Throbber>}>
              <Reports />
            </Suspense>
          }
        >
          <Route
            index
            path={Routing.routes.reports.root}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.reports')}</Throbber>}>
                <ReportsListing />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.reports.historical()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.reports')}</Throbber>}>
                <HistoricalReports />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.reports.legacy()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.reports')}</Throbber>}>
                <LegacyReports />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={Routing.routes.configuration.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
              <Configuration />
            </Suspense>
          }
        >
          <Route
            path={Routing.routes.configuration.monitoring.monitors.root()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <Monitors />
              </Suspense>
            }
          >
            {[
              Routing.routes.configuration.monitoring.monitors.createNew(),
              Routing.routes.configuration.monitoring.monitors.details(),
            ].map(path => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                    <MonitorDetails />
                  </Suspense>
                }
              />
            ))}

            <Route
              path={Routing.routes.configuration.monitoring.monitors.measurementTypes()}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                  <MeasurementTypes />
                </Suspense>
              }
            >
              {[
                Routing.routes.configuration.monitoring.monitors.createMeasurementType(),
                Routing.routes.configuration.monitoring.monitors.editMeasurementType(),
              ].map(path => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                      <MeasurementTypeForm />
                    </Suspense>
                  }
                />
              ))}
            </Route>

            <Route
              path={Routing.routes.configuration.monitoring.monitors.mapConfiguration()}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                  <MapConfiguration />
                </Suspense>
              }
            />

            <Route
              path={Routing.routes.configuration.monitoring.monitors.dataImport()}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                  <DataImport />
                </Suspense>
              }
            >
              {[
                Routing.routes.configuration.monitoring.monitors.addCredential(),
                Routing.routes.configuration.monitoring.monitors.editCredential(),
              ].map(path => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                      <CredentialForm />
                    </Suspense>
                  }
                />
              ))}
            </Route>
          </Route>

          <Route
            path={Routing.routes.configuration.monitoring.measurementGroups.root()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <MeasurementGroups />
              </Suspense>
            }
          >
            {[
              Routing.routes.configuration.monitoring.measurementGroups.create(),
              Routing.routes.configuration.monitoring.measurementGroups.edit(),
            ].map(path => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                    <MeasurementGroup />
                  </Suspense>
                }
              />
            ))}
          </Route>

          <Route
            path={Routing.routes.configuration.monitoring.settings()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <Settings />
              </Suspense>
            }
          />

          <Route
            path={Routing.routes.configuration.modelling.aermet()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <Aermet />
              </Suspense>
            }
          />

          <Route
            path={Routing.routes.configuration.modelling.models()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <Models />
              </Suspense>
            }
          >
            {[Routing.routes.configuration.modelling.edit(), Routing.routes.configuration.modelling.createNew()].map(
              path => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                      <EditModel />
                    </Suspense>
                  }
                />
              )
            )}
          </Route>

          <Route
            path={Routing.routes.configuration.noise.classifications.root}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <NoiseClassifications />
              </Suspense>
            }
          >
            <Route
              path={Routing.routes.configuration.noise.classifications.edit()}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                  <EditNoiseClassification />
                </Suspense>
              }
            />
            <Route
              path={Routing.routes.configuration.noise.classifications.create()}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                  <AddNoiseClassification />
                </Suspense>
              }
            />
          </Route>

          <Route
            path={Routing.routes.configuration.alert.alertResponses.root}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <AlertResponses />
              </Suspense>
            }
          >
            <Route
              path={Routing.routes.configuration.alert.alertResponses.create()}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                  <AddAlertResponseClassification />
                </Suspense>
              }
            />
            <Route
              path={Routing.routes.configuration.alert.alertResponses.edit()}
              element={
                <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                  <EditAlertResponseClassification />
                </Suspense>
              }
            />
          </Route>

          <Route
            path={Routing.routes.configuration.modelling.emissionData()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.siteConfiguration')}</Throbber>}>
                <EmissionData />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={Routing.routes.blasting.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.blasting')}</Throbber>}>
              <Blasting />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.blasting')}</Throbber>}>
                <ScenariosListing />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.blasting.createNew()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.blasting')}</Throbber>}>
                <CreateScenario />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.blasting.viewScenario()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.blasting')}</Throbber>}>
                <ViewScenario />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={Routing.routes.trajectories.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.trajectories')}</Throbber>}>
              <Trajectories />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.trajectories')}</Throbber>}>
                <TrajectoriesListing />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.trajectories.create()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.trajectories')}</Throbber>}>
                <CreateNewTrajectory />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.trajectories.view()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.trajectories')}</Throbber>}>
                <ViewTrajectory />
              </Suspense>
            }
          />
          <Route
            element={
              <Suspense fallback={<Throbber>{l10n('activity.loading')}</Throbber>}>
                <NotFound />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={Routing.routes.alerts.root}
          element={
            <Suspense fallback={<Throbber>{l10n('loading.screen.alerts')}</Throbber>}>
              <Alerts />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.alerts')}</Throbber>}>
                <AlertHistory />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.alerts.view()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.alerts')}</Throbber>}>
                <ViewAlert />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.alerts.events()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.events')}</Throbber>}>
                <EventHistory />
              </Suspense>
            }
          />
          <Route
            path={Routing.routes.alerts.vibrationEvents()}
            element={
              <Suspense fallback={<Throbber>{l10n('loading.screen.vibrationEvents')}</Throbber>}>
                <VibrationEvents />
              </Suspense>
            }
          />
          <Route element={<NotFound />} />
        </Route>
        <Route
          path={Routing.routes.incidentIntelligence.tickets()}
          element={
            <Suspense fallback={l10n('loading.screen.incidentIntelligence')}>
              <IncidentIntelligenceTickets />
            </Suspense>
          }
        />
        {FALLBACK_ROUTES.map(route => (
          <Route key={route} path={route} element={<Navigate to={fallbackRoute} />} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>
    </>
  );

  return createRoutesFromElements(routes);
};

const UnknownError = () => {
  return <UnexpectedError error={new Error('Unknown error')} componentStack="" />;
};

const RouteErrorBoundary = () => {
  const error = useRouteError();
  if (!(error instanceof Error)) return <UnknownError />;
  if (!error || typeof error !== 'object' || !('stack' in error) || typeof error.stack !== 'string')
    return <UnknownError />;
  return <UnexpectedError error={error} componentStack={error.stack} />;
};
