import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import * as SecurityService from 'app/business-logic/services/security-service';
import { accessControlsChanged } from 'app/redux/profile/user/actions';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { LogManager } from 'core/logging/LogManager';
import { useEffect } from 'react';

const logger = LogManager.getLogger('useAccessControlsQuery');

export const useAccessControlsQuery = () => {
  const dispatch = useAppDispatch();
  const { facility } = useAppSelector(state => state.profile);
  const { companyId, id } = facility;
  const accessControlsQuery = useQuery({
    queryKey: [QUERY_KEY.authAccessControls, companyId, id],
    queryFn: () => {
      if (!companyId || !id) throw new Error('No preferred facility specified');
      return SecurityService.fetchAccessControls(companyId, id);
    },
    enabled: !!companyId && !!id,
  });
  const { data, isSuccess, isError } = accessControlsQuery;
  useEffect(() => {
    if (isError) {
      return logger.warn('Failed to fetch access controls');
    }
    if (isSuccess) {
      if (!data) return logger.warn('No access controls');
      logger.debug('Setting access controls');
      dispatch(accessControlsChanged(data));
    }
  }, [isSuccess, isError, data, dispatch]);

  return accessControlsQuery;
};
