import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessControl } from 'app/business-logic/security/KnownAccessControls';
import { TIcon } from 'app/components/icon';
import React, { useMemo, ComponentPropsWithoutRef } from 'react';
import { useMatch, Link } from 'react-router-dom';

import { NavItemIcon, NavItemLinkTitle } from './NavigationBar.styles';
import { NavItemLink } from './NavItem.styles';

export interface INavItem {
  key: string;
  title: string;
  icon: TIcon;
  accessControls?: AccessControl[];
  isExternal?: boolean;
  isDisabled?: boolean;
  link?: string;
  path?: string;
  elementProps?: ComponentPropsWithoutRef<typeof NavItemLink>;
}

const NavItemContainer = ({
  link,
  path,
  isExternal,
  children,
  isDisabled,
  elementProps,
}: React.PropsWithChildren<INavItem>) => {
  const isActive = useIsActive(isExternal, path);
  const sharedProps = useMemo(
    () => ({
      $isActive: isActive,
      $isDisabled: isDisabled,
    }),
    [isActive, isDisabled]
  );

  if (isExternal) {
    return (
      <NavItemLink target="_blank" rel="noreferrer" {...elementProps} {...sharedProps} href={link}>
        {children}
      </NavItemLink>
    );
  }

  if (link) {
    return (
      <NavItemLink as={Link} to={link} {...elementProps} {...sharedProps}>
        {children}
      </NavItemLink>
    );
  }

  return (
    <NavItemLink as="span" {...elementProps} {...sharedProps}>
      {children}
    </NavItemLink>
  );
};

export const NavItem = (props: INavItem) => {
  const { icon: Icon, isExternal, title } = props;

  return (
    <NavItemContainer {...props}>
      <NavItemIcon>
        <Icon />
      </NavItemIcon>
      <NavItemLinkTitle>{title}</NavItemLinkTitle>
      {isExternal && (
        <NavItemIcon $isExternal={isExternal}>
          <FontAwesomeIcon icon={faExternalLink} />
        </NavItemIcon>
      )}
    </NavItemContainer>
  );
};

function useIsActive(isExternal: boolean | undefined, path: string | undefined) {
  const matches = useMatch({ path: path ?? '', end: false });
  if (isExternal) return false;
  if (!path) return false;
  return !!matches;
}

export default NavItem;
