import { ComponentPropsWithRef, InputHTMLAttributes, PropsWithChildren } from 'react';
import { CheckboxInput, CheckboxLabel, CheckboxLabelText, CheckboxLabelTextInner } from './Checkbox.styles';

type StyledProps = ComponentPropsWithRef<typeof CheckboxInput>;

type CheckboxProps = PropsWithChildren<
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    hasError?: boolean;
    labelProps?: Parameters<typeof CheckboxLabel>[0] & { 'data-tracking'?: string };
    labelTextProps?: Parameters<typeof CheckboxLabelText>[0];
  } & StyledProps
>;

export const Checkbox = ({
  label,
  hasError,
  labelProps,
  labelTextProps,
  children,
  $size,
  ...inputProps
}: CheckboxProps) => {
  return (
    <CheckboxLabel {...labelProps}>
      <CheckboxInput {...inputProps} $hasError={hasError} $size={$size} />
      <CheckboxLabelText {...labelTextProps}>
        <CheckboxLabelTextInner $size={$size}>{label ?? children}</CheckboxLabelTextInner>
      </CheckboxLabelText>
    </CheckboxLabel>
  );
};
