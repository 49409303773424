import { CaseReducer } from '@reduxjs/toolkit';
import { fetchEmissionSources } from '../../fetchEmissionSources';
import { ModellingState } from '../../slice';

export const fetchEmissionSourcesRejected: CaseReducer<
  ModellingState,
  ReturnType<typeof fetchEmissionSources.rejected>
> = (state, { meta: { requestId } }) => {
  state.data.emissionSources = { status: 'Failure', requestId };
};
