import { ReactComponent as Logo } from 'app/visuals/images/logo.svg';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Inner = styled.div`
  max-width: 100%;
  width: 800px;
  padding: 36px;
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  border: 1px solid ${({ theme }) => theme.colors.mono.ui03};
  p {
    font-size: 1.4rem;
  }
`;

export const ErrorLogo = styled(Logo)`
  fill: white;
  max-width: 240px;
  width: 100%;
  height: auto;
`;

export const MainHeading = styled.h1`
  font-size: 2.8rem;
  margin: 1em 0 0.5em;
  font-weight: normal;
`;

export const SubHeading = styled.h2`
  font-size: 1.8rem;
  margin: 1em 0 0.5em;
  font-weight: normal;
`;

export const ErrorBlock = styled.textarea.attrs({ readOnly: true })`
  font-family: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
    'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  padding: ${({ theme }) => theme.spacing(2)}px;
  font-size: 1.6rem;
  background-color: ${({ theme }) => theme.colors.mono.ui01};
  border: 1px solid ${({ theme }) => theme.colors.mono.ui02};
  width: 100%;
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.mono.text01};
  height: 15vh;
  resize: vertical;
  min-height: 5em;
`;

// Using anchor element instead of <Link /> because when an unexpected error
// is thrown, the error boundary is outside the scope of the <Router />
export const StyledLink = styled.a`
  && {
    display: inline-block;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.mono.text01};
    background-color: ${({ theme }) => theme.colors.brand.brand01};
    padding: ${({ theme: { spacing } }) => `${spacing(1.5)}px ${spacing(2)}px`};
    border-radius: 3px;
    line-height: 1;
    font-weight: ${({ theme }) => theme.font.default.weight.normal};
    text-transform: uppercase;
    margin-top: ${({ theme }) => theme.spacing(4)}px;
    &:hover {
      color: ${({ theme }) => theme.colors.mono.text01};
      text-decoration: none;
    }
  }
`;

export const Text = styled.p`
  font-size: 1.6rem;
`;
