import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';
import { IRawKmlFile } from 'app/business-logic/services/map-service/types';

export const fetchOverlayFileData = async (fileName: string) => {
  const { data, isSuccess }: HttpResponse<IRawKmlFile> = await httpClient.useInternalApi
    .asAuthorised()
    .get(`/configuration/blasting/scenarios/overlay?fileName=${fileName}`);

  if (!isSuccess || !data) throw new Error('Failed to fetch overlay file data');
  return data;
};
