import { httpClient } from 'core/net/http';
import { stringify } from 'query-string';
import { fetchOverlayFileData } from 'app/business-logic/services/blasting-service/fetchOverlayFileData';
import { fetchTempOverlayFiles } from 'app/business-logic/services/blasting-service/fetchTempOverlayFiles';
import HttpResponse from 'core/net/HttpResponse';
import { IRawKmlFile } from './types';

export async function fetchKmlOverlays({
  includeFiles,
  ignoreFiles,
}: { includeFiles?: string[]; ignoreFiles?: string[] } = {}) {
  const files = stringify({ includeFiles, ignoreFiles });
  const { data, isError, error }: HttpResponse<IRawKmlFile[]> = await httpClient.useInternalApi
    .asAuthorised()
    .get(`map/kml-files?${files}`);
  if (!data || isError) throw new Error(JSON.stringify(error));
  return data;
}

export const fetchTempBlastingKmlOverlays = async () => {
  const kmlFiles = await fetchTempOverlayFiles();
  const kmlData = await Promise.all(kmlFiles.map(file => fetchOverlayFileData(file.name)));
  return kmlData;
};
