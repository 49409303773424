import { DefaultTheme } from 'styled-components';
import { Z_INDEX_HEADER_BAR, Z_INDEX_MAP, Z_INDEX_MAP_CONTROL_BOX } from './constants';
import { BREAKPOINTS } from './breakpoints';

export type Theme = typeof THEME;

const SPACING_UNIT = 8;

export const THEME: DefaultTheme = {
  spacing: (multiple = 1) => SPACING_UNIT * multiple,
  colors: {
    mono: {
      ui01: '#171719',
      ui02: '#202123',
      ui03: '#262a2d',
      ui04: '#373d43',
      ui05: '#5a6872',
      ui06: '#c6cdd2',
      text01: '#fff',
      text02: '#c6cdd2',
      text03: '#98a5ae',
    },
    threshold: {
      low: '#a0f751',
      lowmid: '#d1d40e',
      mid: '#f9ef00',
      midhigh: '#ff7326',
      high: '#dc4545',
    },
    brand: {
      brand01: '#0081a1',
      brand02: '#59cbe8',
    },
    trajectory: {
      primary: '#785ef0',
      secondary: '#ffffff',
    },
    feedback: {
      error: '#dc4545',
      success: '#a0f751',
      warning: '#d1d40e',
    },
  },
  font: {
    default: {
      family: 'Inter, Arial, Helvetica, sans-serif',
      weight: {
        light: 200,
        normal: 400,
        bold: 700,
      },
    },
    alternative: {
      family: '"Source Sans Pro", monospace',
    },
  },
  blueprint: {
    namespace: 'bp5',
  },
  zIndex: {
    map: Z_INDEX_MAP,
    mapControlBox: Z_INDEX_MAP_CONTROL_BOX,
    headerBar: Z_INDEX_HEADER_BAR,
  },
  breakpoints: BREAKPOINTS,
};
